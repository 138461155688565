import * as Cookies from "js-cookie";
import { initValidationOn, validate, validatePassword } from "./validation.js";
import { getParamByName } from "../../common/utilities/queryString.js";
import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

// Templates
import errorSummary from "../templates/errorSummary.js";
import {
    warningVerification,
    errorboxSuspension,
    errorboxLogin,
    errorboxTooManyLoginAttempts,
} from "../templates/messagesLogin.js";
import {
    registrationSuccess,
    registrationNeedToVerify,
    registrationUserExists,
    employerRegistrationSuccess,
} from "../templates/messagesRegistration.js";
import { verificationTooManyAttemps } from "../templates/messagesVerification.js";
import { doResetFailure } from "../templates/messagesResetPassword.js";
import refreshNavbar from "./navbar.js";

/**
 * Looks for the `page_load` cookie and tries to extract and parse the JWT it contains
 * @returns {(boolean|{email: String, timestamp: String})} False - if the user is logged out
 * The user object - if the user is logged in
 */
function checkLoginStatus() {
    try {
        let pageLoad = Cookies.get("page_load");
        if (pageLoad) {
            pageLoad = pageLoad.match(/^.+\.(.+)\..+$/);
            return JSON.parse(window.atob(pageLoad[1]));
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}

/**
 * Initialises the authentication flows
 * (Shared between jobseeker and employer sections)
 */
function init() {
    // Show the appropriate navbar links (jobseeker or employer, logged in or not)
    refreshNavbar();

    // Init the new toggle-able password fields
    initPasswordFields();

    // If we're on the login page let's also initialise the login form
    let $loginForm = $("form[name=login]");
    if ($loginForm.length) {
        initLoginForm($loginForm);
    }

    // if we're on the registration page let's also initialise the registration form
    let $registrationForm = $("form[name=registration]");
    if ($registrationForm.length) {
        initRegistrationForm($registrationForm);
    }

    // If we're on the verify account page let's also initialise the verification form
    let $verificationForm = $("form[name=verify]");
    if ($verificationForm.length) {
        initVerificationForm($verificationForm);
    }

    // If we're on the reset password page, let's initialise the forms
    let $requestResetForm = $("form[name=requestReset]");
    if ($requestResetForm.length) {
        initRequestResetForm($requestResetForm);
    }

    // Reset password page, do-reset form
    let $doResetForm = $("form[name=doReset]");
    if ($doResetForm.length) {
        initDoResetForm($doResetForm);
    }
}

/**
 * Setup events for the login form on /sign-in
 * @param {jQuery} $loginForm - A jQuery reference to form[name=login]
 */
function initLoginForm($loginForm) {
    if ($loginForm.length) {
        // Login form is present. Lets setup appropriate events.
        // Email and password fields might have errors when form submits,
        // so we will set up the appropriate error handling first
        initValidationOn($("#email"));
        initValidationOn($("#password"));
        // Capture submit event from form and validate fields first
        $loginForm.on("submit", function (e) {
            e.preventDefault();
            let proceed = true,
                $email = $("#email"),
                $password = $("#password"),
                errors = [];

            // Before we do anything else, let's clear any box error boxes
            $(".govuk-error-summary").remove();
            $email.trigger("clearError");
            $password.trigger("clearError");

            if (!validate($email, "EMAIL")) {
                proceed = false;
                $email.trigger("raiseError", [t("PAGE:SIGN_IN:EMAIL:ERROR")]);
                errors.push({ id: "email", error: t("PAGE:SIGN_IN:EMAIL:ERROR") });
            }
            if (!validate($password)) {
                proceed = false;
                $password.trigger("raiseError", [t("PAGE:SIGN_IN:PASSWORD:ERROR")]);
                errors.push({ id: "password", error: t("PAGE:SIGN_IN:PASSWORD:ERROR") });
            }
            if (proceed) {
                // We have an email and a a valid password. Let's proceed
                login($email.val(), $password.val())
                    .done(function (response) {
                        if (response["uid"] || (response["error"] && response["error"] === "logged_in")) {
                            // Either a successful login or the user is already logged in.
                            afterLogin();
                        } else if (response["error"]) {
                            $(".error-summary").remove();
                            switch (response["error"]) {
                                case "bad_email_or_password":
                                    $("form[name=login]").before(errorboxLogin());
                                    break;
                                case "user_not_verified":
                                    $("form[name=login]").before(
                                        warningVerification(response.email, path.employerStaticPages),
                                    );
                                    break;
                                case "user_suspended":
                                    $("form[name=login]").before(errorboxSuspension(response.email));
                                    break;
                            }
                        }
                    })
                    .fail(function (xhr) {
                        if (xhr.status === 429) {
                            $("form[name=login]").before(errorboxTooManyLoginAttempts());
                        }
                        if (xhr.status === 401 && xhr.responseJSON.error == "MUST_RESET_PASSWORD") {
                            location.replace(`${path.host}/reset-password`);
                        }
                    });
            } else {
                initErrorSummary(errors);
            }
        });
    }
}

/**
 * Setup events for the registration form on /registser
 * @param {jQuery} $registrationForm - A jQuery reference to form[name=registration]
 */
function initRegistrationForm($registrationForm) {
    if ($registrationForm.length) {
        initValidationOn($("#email"));
        initValidationOn($("#password"));
        $registrationForm.on("submit", function (e) {
            e.preventDefault();
            let proceed = true,
                $email = $("#email"),
                $password = $("#password"),
                errors = [];

            // Remove previous error summaries
            $(".govuk-error-summary").remove();
            resetPageTitle();

            // Clear any previous errors
            $email.trigger("clearError");
            $password.trigger("clearError");

            if (!validate($email, "EMAIL")) {
                proceed = false;
                $email.trigger("raiseError", [t("PAGE:CREATE_ACCOUNT:EMAIL:ERROR")]);
                errors.push({ id: "email", error: t("PAGE:CREATE_ACCOUNT:EMAIL:ERROR") });
            }
            let check = validatePassword($password.val());
            if (check !== undefined) {
                proceed = false;
                $password.trigger("raiseError", [check]);
                errors.push({ id: "password", error: check });
            }
            if (proceed) {
                // We have an email and a a valid password. Let's proceed
                register($.trim($email.val()), $password.val(), path.employerStaticPages)
                    .done((response) => {
                        let $step1 = $(".registration-step-1");
                        if (response["uid"]) {
                            // User registration was successful
                            let html = registrationSuccess($email.val());
                            if (path.employerStaticPages) {
                                html = employerRegistrationSuccess($email.val());
                            }
                            $step1.replaceWith(html);
                            window.scrollTo(0, 0);
                        } else if (response["error"]) {
                            switch (response["error"]) {
                                case "email_invalid":
                                    $email.trigger("raiseError", [t("PAGE:CREATE_ACCOUNT:EMAIL:ERROR")]);
                                    errors.push({ id: "email", error: t("PAGE:CREATE_ACCOUNT:EMAIL:ERROR") });
                                    break;
                                case "user_unverified":
                                    $step1.replaceWith(
                                        registrationNeedToVerify($email.val(), path?.employerStaticPages),
                                    );
                                    break;
                                case "user_exists":
                                    $(".error-summary").remove();
                                    $step1.find("form").before(registrationUserExists(path?.employerStaticPages));
                                    break;
                                case "password_short":
                                    $password.trigger("raiseError", [t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:SHORT")]);
                                    errors.push({
                                        id: "password",
                                        error: t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:SHORT"),
                                    });
                                    break;
                                case "password_no_letter":
                                    $password.trigger("raiseError", [t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:LETTER")]);
                                    errors.push({
                                        id: "password",
                                        error: t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:LETTER"),
                                    });
                                    break;
                                case "password_no_number":
                                    $password.trigger("raiseError", [t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:NUMBER")]);
                                    errors.push({
                                        id: "password",
                                        error: t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:NUMBER"),
                                    });
                                    break;
                                case "password_banned":
                                    $password.trigger("raiseError", [t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:BAN")]);
                                    errors.push({
                                        id: "password",
                                        error: t("PAGE:CREATE_ACCOUNT:PASSWORD:RULE:BAN"),
                                    });
                                    break;
                            }
                            if (errors.length > 0) {
                                initErrorSummary(errors);
                            }
                        }
                    })
                    .fail(function (xhr) {
                        if (xhr.status === 429) {
                            $registrationForm.before(verificationTooManyAttemps());
                        }
                    });
            } else {
                initErrorSummary(errors);
            }
        });
    }
}

function initVerificationForm($verificationForm) {
    if ($verificationForm.length) {
        initValidationOn($("#email"));
        // Capture submit event from form and validate fields first
        $verificationForm.on("submit", function (e) {
            e.preventDefault();
            let proceed = true,
                $email = $("#email"),
                errors = [];

            // Remove previous errors
            $(".govuk-error-summary").remove();
            $email.trigger("clearError");

            if (!validate($email, "EMAIL")) {
                proceed = false;
                $email.trigger("raiseError", [t("PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:INVALID")]);
                errors.push({ id: "email", error: t("PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:INVALID") });
            }
            if (proceed) {
                // References to the divs we'll be hiding / showing
                let $step1 = $("[data-js=verification-step-1]");
                let $step2 = $("[data-js=verification-step-2]");
                resetPageTitle();
                verify($email.val(), path.employerStaticPages)
                    .done(function (response) {
                        if (response["ok"]) {
                            $step1.hide();
                            $step2.find("#verification-email").html($email.val());
                            $step2.removeClass("govuk-!-display-none");
                        } else if (response["error"]) {
                            switch (response["error"]) {
                                case "no_such_user":
                                    // "This account does not exist. Enter the email address you used to create your account"
                                    $email.trigger("raiseError", [
                                        t("PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:NO_SUCH_USER"),
                                    ]);
                                    errors.push({
                                        id: "email",
                                        error: t("PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:NO_SUCH_USER"),
                                    });
                                    break;
                                case "user_already_verified":
                                    // "This account has already been verified."
                                    $email.trigger("raiseError", [
                                        t("PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:ACCOUNT_ACTIVE:1"),
                                    ]);
                                    // "This account has already been verified. <a>Sign in to your account</a>"
                                    errors.push({
                                        id: "email",
                                        noLink: true,
                                        error: `<strong>${t(
                                            "PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:ACCOUNT_ACTIVE:1",
                                        )}</strong>. <a href="${path.host}${
                                            path.employerStaticPages ? "/employer" : ""
                                        }/sign-in" class="govuk-link">${t(
                                            "PAGE:VERIFY_EMAIL:FORM:EMAIL:ERROR:ACCOUNT_ACTIVE:2",
                                        )}</a>`,
                                    });
                                    break;
                            }
                            if (errors.length) {
                                initErrorSummary(errors);
                            }
                        }
                    })
                    .fail(function (xhr) {
                        if (xhr.status === 429) {
                            // Show a custom "too many attempts" error message
                            errors.push({
                                id: "email",
                                error: t("PAGE:VERIFY_EMAIL:ERROR:TOO_MANY_ATTEMPTS:TEXT"),
                                noLink: true,
                            });
                            if (errors.length) {
                                initErrorSummary(errors);
                            }
                        }
                    });
            } else if (errors.length) {
                initErrorSummary(errors);
            }
        });
    }
}

/**
 * Setup events for the reset pwd form on /reset-password (no token)
 * @param {jQuery} $requestResetForm - A jQuery reference to form[name=requestReset]
 */
function initRequestResetForm($requestResetForm) {
    if ($requestResetForm.length) {
        initValidationOn($("#email"));
        // Capture submit event from form and validate fields first
        $requestResetForm.on("submit", function (e) {
            e.preventDefault();
            let proceed = true,
                $email = $("#email"),
                errors = [];

            // Remove previous errors
            $(".govuk-error-summary").remove();
            $email.trigger("clearError");

            if (!validate($email, "EMAIL")) {
                proceed = false;
                $email.trigger("raiseError", [t("PAGE:RESET_PASSWORD:REQUEST:EMAIL:ERROR")]);
                errors.push({ id: "email", error: t("PAGE:RESET_PASSWORD:REQUEST:EMAIL:ERROR") });
            }
            if (proceed) {
                // References to the divs we'll be hiding / showing
                let $step1 = $("[data-js=forgot-pass-step-1]");
                let $step2 = $("[data-js=forgot-pass-step-2]");
                // We have an email. Let's proceed
                requestReset($email.val(), path?.employerStaticPages)
                    .done(() => {
                        // Irrespective on whether response["ok"] or response["error"] is present
                        // we'll be showing a success message (to avoid people exploiting this endpoint
                        // to establish if a user has registered with FJ)
                        $step1.hide();
                        $("#forgot-pass-email").html($email.val());
                        $step2.removeClass("govuk-!-display-none");
                        if (path?.employerStaticPages) {
                            document.title = t("EMPLOYER:PAGE:FORGOT_PASSWORD:CHECK_INBOX:TITLE");
                        } else {
                            document.title = t("PAGE:FORGOT_PASSWORD:CHECK_INBOX:TITLE");
                        }
                    })
                    .fail((xhr) => {
                        if (xhr.status === 429) {
                            // BE returned a 429 with a "Too Many Requests" error
                            // Raise only an error summary with an error message (no link to input field)
                            errors.push({
                                id: "email",
                                error: t("PAGE:RESET_PASSWORD:TOO_MANY_ATTEMPTS:TEXT"),
                                noLink: true,
                            });
                            initErrorSummary(errors);
                        } else {
                            $step1.replaceWith(resetRequestFailed());
                        }
                    });
            } else {
                initErrorSummary(errors);
            }
        });
    }
}

/**
 * Setup events for the reset pwd form on /reset-password?token={a valid token here}
 * @param {jQuery} $doResetForm - A jQuery reference to form[name=doReset]
 */
function initDoResetForm($doResetForm) {
    if ($doResetForm.length) {
        initValidationOn($("#password"));
        $doResetForm.on("submit", function (e) {
            e.preventDefault();
            let proceed = true,
                $password = $("#password"),
                token = $("#token").val(),
                errors = [];
            // Clear any previous errors
            $(".govuk-error-summary").remove();
            $password.trigger("clearError");
            // Validate current password
            let check = validatePassword($password.val());
            if (check !== undefined) {
                proceed = false;
                $password.trigger("raiseError", [check]);
                errors.push({ id: "password", error: check });
            }
            if (proceed) {
                // References to the divs we'll be hiding / showing
                let $step1 = $("[data-js=reset-pass-step-1]");
                let $step2 = $("[data-js=reset-pass-step-2]");
                doReset($password.val(), token).done(function (response) {
                    if (response["ok"]) {
                        $step1.hide();
                        $step2.removeClass("govuk-!-display-none");
                        // Update the page title
                        if (path?.employerStaticPages) {
                            document.title = t("EMPLOYER:PAGE:RESET_PASSWORD:SUCCESS:TITLE");
                        } else {
                            document.title = t("PAGE:RESET_PASSWORD:SUCCESS:TITLE");
                        }
                        // The BE will have signed-in the user on a successful response
                        // Refresh the navbar links:
                        refreshNavbar();
                    } else if (response["error"]) {
                        switch (response["error"]) {
                            case "password_short":
                                $password.trigger("raiseError", [t("PAGE:RESET_PASSWORD:PASSWORD:RULE:SHORT")]);
                                break;
                            case "password_no_letter":
                                $password.trigger("raiseError", [t("PAGE:RESET_PASSWORD:PASSWORD:RULE:LETTER")]);
                                break;
                            case "password_no_number":
                                $password.trigger("raiseError", [t("PAGE:RESET_PASSWORD:PASSWORD:RULE:NUMBER")]);
                                break;
                            case "password_banned":
                                $password.trigger("raiseError", [t("PAGE:RESET_PASSWORD:PASSWORD:RULE:BAN")]);
                                break;
                        }
                        // If the response payload includes a token, repopulate the $("#token") hidden field
                        if (response["token"]) {
                            $("#token").val(response["token"]);
                        }
                    } else {
                        $("form[name=doReset]").before(doResetFailure());
                    }
                });
            } else {
                initErrorSummary(errors);
            }
        });
    }
}

function login(email, password) {
    if (!email || !password) {
        return false;
    }
    return $.ajax({
        url: `${path.host}/do_login?_ie_cachebust=${Math.random()}`,
        type: "POST",
        cache: false,
        data: {
            email,
            password,
        },
    });
}

/**
 * Handles the redirection after a succesfull login
 */
function afterLogin() {
    let url = path.host;
    // Do we have an `after_login` parameter?
    let r = getParamByName("after_login");
    if (r) {
        // Strip first char if it's a forward slash (in some cases it will be)
        if (r.charAt(0) === "/") {
            r = r.substring(1);
        }
        url += "/" + r;
        // Do we also have an "after_login_intention" url param?
        // If yes, validate it's a proper one and append to the redirect url
        // Intentions currently supported:
        // 1) fav_{id} - tells the search results JS layer to scroll to the job in question and add it to favourites
        let intention = getParamByName("after_login_intention");
        if (intention && intention.match(/^fav_\d+$/)) {
            url += "&intention=" + intention;
        }
    } else {
        // Default action: Redirect to /your-account or to /employer
        if (path?.employerStaticPages) {
            url += "/employer/dashboard";
        } else if (getParamByName("support")) {
            // Is the user trying to login to support?
            url = path.support;
        } else {
            url += "/your-account";
        }
    }
    // Do the redirection
    window.location = url;
}

function logout() {
    // FJ-1512: Need to add the cookie domain when deleting page_load cookie
    // to make Safari behave (page_load is a secure but not httpOnly cookie)
    Cookies.remove("page_load", { domain: path.cd });
    Cookies.remove("employerAuth");
    Cookies.remove("supportAuth");
    Cookies.remove("user_activity");
    return $.ajax({
        url: `/do_logout?_ie_cachebust=${Math.random()}`,
        type: "GET",
    });
}

function register(email, password, employer = 0) {
    // Add the honeypot
    let $melikoupa = $(".melikoupa input");
    let data = {
        email,
        password,
        employer: employer ? 1 : 0,
        [$melikoupa.attr("name")]: $melikoupa.val(),
    };
    // Check to see if we have an apply intention
    let intention = Cookies.get("uj_intention");
    let lastQuery = Cookies.get("uj_last_query");

    let applyIntention = intention ? intention.match(/^apply_(\d+)$/) : false;
    let viewIntention = intention ? intention.match(/^view_(\d+)$/) : false;

    // If the user did not sign up from a ad details page then the intention is to go back to a search page
    if (lastQuery && !intention) {
        // Add the ad ID to the data payload as well as intention
        data.uj_last_query = lastQuery;
        data.intention = "search";
        // Remove the intention cookie
        Cookies.remove("uj_last_query");
    }

    if (intention && applyIntention) {
        // Add the ad ID to the data payload as well as intention
        data.ad_id = applyIntention[1];
        data.intention = "apply";
        // Remove the intention cookie
        Cookies.remove("uj_intention");
    }

    if (intention && viewIntention) {
        // Add the ad ID to the data payload as well as intention
        data.ad_id = viewIntention[1];
        data.intention = "view";
        // Remove the intention cookie
        Cookies.remove("uj_intention");
    }

    data.lang_code = path.lang_code;

    return $.ajax({
        url: path.host + "/create_user",
        type: "POST",
        cache: false,
        data,
    });
}

function verify(email, isEmployer = false) {
    if (!email) {
        return false;
    }
    return $.ajax({
        url: path.host + "/send-verification",
        type: "GET",
        cache: false,
        data: { email, employer: isEmployer ? 1 : 0 },
    });
}

function requestReset(email, isEmployer = false) {
    if (!email) {
        return false;
    }
    let data = {
        email: encodeURIComponent(email),
    };
    if (isEmployer) {
        data.employer = 1;
    }
    return $.ajax({
        url: path.host + "/password-reset",
        type: "GET",
        cache: false,
        data: data,
    });
}

function doReset(password, token) {
    if (!password || !token) {
        return false;
    }
    return $.ajax({
        url: path.host + "/do-password-reset",
        type: "POST",
        cache: false,
        data: {
            password,
            token,
        },
    });
}

function initErrorSummary(errors) {
    // Clear any previously showing error summaries
    $(".govuk-error-summary").remove();
    // The user has validation errors and we need to update the view with an error summary and new document title
    if (!document.title.includes(t("FAJ:ERROR"))) {
        document.title = `${t("FAJ:ERROR")} ${document.title}`;
    }
    $("#errorSummary").before(errorSummary(errors));
    $(".govuk-error-summary").focus();
}

function resetPageTitle() {
    let pageTitle = document.title;
    if (pageTitle.includes(t("FAJ:ERROR"))) {
        document.title = pageTitle.replace(`${t("FAJ:ERROR")} `, "");
    }
}

/**
 * Initializes the password fields with toggle functionality to show/hide passwords.
 * This function sets up click event handlers on elements with the class `govuk-js-password-input-toggle`.
 * When clicked, the associated password input field's type is toggled between "password" and "text",
 * and the button text and aria-label are updated accordingly.
 * (See https://design-system.service.gov.uk/components/password-input/)
 */
function initPasswordFields() {
    let $toggles = $(".govuk-js-password-input-toggle");
    if ($toggles.length) {
        $toggles.each(function () {
            $(this).on("click", function (e) {
                console.log($(this));
                e.preventDefault();
                let $input = $(this).closest(".govuk-password-input__wrapper").find("input");
                console.log($input.length);
                if ($input.is(":password")) {
                    // We should show the password
                    $input
                        // Show the password by changing the field type to "text"...
                        .attr("type", "text")
                        // Find the visually hidden helper div and update its text...
                        .next(".govuk-password-input__sr-status")
                        // "Your password is visible"
                        .text(t("FAJ:PASSWORD:VISIBLE"));
                    $(this).text(t("FAJ:PASSWORD:HIDE")).attr("aria-label", t("FAJ:PASSWORD:HIDE:ARIA_LABEL"));
                } else {
                    $input
                        // Hide the password by changing the field type to "password"...
                        .attr("type", "password")
                        // Find the visually hidden helper div and update its text...
                        .next(".govuk-password-input__sr-status")
                        // "Your password is hidden"
                        .text(t("FAJ:PASSWORD:HIDDEN"));
                    $(this).text(t("FAJ:PASSWORD:SHOW")).attr("aria-label", t("FAJ:PASSWORD:SHOW:ARIA_LABEL"));
                }
            });
        });
    }
}

export { init, checkLoginStatus, logout };
