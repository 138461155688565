import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function errorboxSuspension(email) {
    return `
<div class="error-summary" tabindex="-1" role="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:SIGN_IN:ERROR:SUSPENDED")}</h2>
    <p class="govuk-body">${t(
        "PAGE:SIGN_IN:ERROR:SUSPENDED:TEXT",
        `<strong>${email}</strong>`,
        `<a class="govuk-link" href="${path.host}/contact-us">`,
        `</a>`
    )}</p>
</div>`;
}

export function warningVerification(email, is_employer = false) {
    let url = path.host;
    if (is_employer) {
        url += "/employer";
    }
    url += "/verify-email";
    return `
<div class="alert alert-warning govuk-!-margin-bottom-8" tabindex="-1" role="alert">
    <h2 class="alert-heading">${t("PAGE:SIGN_IN:ERROR:NOT_VERIFIED")}</h2>
    <p class="govuk-body">${t(
        "PAGE:SIGN_IN:ERROR:NOT_VERIFIED:TEXT",
        `<a class="govuk-link govuk-link--inverse" href="${url}">`,
        "</a>"
    )}</p>
</div>`;
}

export function errorboxLogin() {
    return `
<div class="error-summary" tabindex="-1" role="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:SIGN_IN:ERROR:LOGIN")}</h2>
    <p class="govuk-body">${t("PAGE:SIGN_IN:ERROR:LOGIN:TEXT")}</p>
</div>`;
}

export function errorboxTooManyLoginAttempts() {
    return `
<div class="error-summary" tabindex="-1" role="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:SIGN_IN:ERROR:TOO_MANY_LOGINS")}</h2>
    <p class="govuk-body">${t("PAGE:SIGN_IN:ERROR:TOO_MANY_LOGINS:TEXT")}</p>
</div>`;
}
