import * as Cookies from "js-cookie";
import { checkLoginStatus, logout } from "./authentication.js";
import { employerLoggedOut, jobseekerLoggedOut } from "../templates/navbarLoggedOut.js";
import { employerLoggedIn, jobseekerLoggedIn } from "../templates/navbarLoggedIn.js";

/**
 * Populates the navbar with the appropriate links, depending on whether:
 * - We have a jobseeker or employer user
 * - User is signed in or not
 */
function refreshNavbar() {
    let $navbar = $(".govuk-header-auth ul");
    // Before we do any transformations to the navbar, let's check the number of <li>
    // elements it has. If we get > 1, it means we've already rendered some items,
    // so we'll need to remove those (all except the last one, the locale switch which is rendered by the BE)
    if ($navbar.find("li").length > 1) {
        $navbar.find("li:not(:last-child)").remove();
    }
    // If we're on the /sign-out page we'll logout
    // FJ-1694: Also log out on the "account deleted" page
    if ($(".page_logout").length || $(".page_account_deleted").length) {
        logout().then(function () {
            $navbar.prepend(jobseekerLoggedOut());
        });
    } else if ($(".page_employer_sign_out").length) {
        logout().then(function () {
            $navbar.prepend(employerLoggedOut());
        });
    } else {
        // We're on any other page of the site, proceed with our normal checks
        let authCheck = checkLoginStatus();
        if (authCheck) {
            // We have a logged-in user. Let's change the header to reflect this
            // FJ-354 for employer static pages we need to alter the nav and footer links
            if (window?.path?.employerStaticPages) {
                $navbar.prepend(employerLoggedIn(authCheck.email));
            } else {
                // FJ-1699: If the currently logged-in user is an employer
                // (and is on the jobseeker side of things) we ideally want to let
                // jobseekerLoggedIn() know about this
                $navbar.prepend(jobseekerLoggedIn(authCheck.email, Cookies.get("employerAuth")));
            }
        } else {
            if (window?.path?.employerStaticPages) {
                $navbar.prepend(employerLoggedOut());
            } else {
                $navbar.prepend(jobseekerLoggedOut());
            }
        }
    }
}

export default refreshNavbar;
