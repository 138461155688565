import { checkLoginStatus } from "../utilities/authentication.js";
import { getSearchLink } from "../../common/utilities/util.js";
import { unsaveJobAdvert, saveJobAdvert } from "../templates/details.js";
import debounce from "lodash.debounce";

import * as Cookies from "js-cookie";

const auth = checkLoginStatus();

function init() {
    // Init the favourites functionality
    // Init View last viewed job
    initDetailsIntention();
    // This adds the last search to the breadcrumbs search link
    $(".js-search-results").attr("href", getSearchLink());
}

/**
 * The debounced version of updateFavourite (see above)
 * Uses lodash.debounce to ensure this is not fired continuously by click-happy users
 */
const debouncedUpdateFavourite = debounce(updateFavourite, 500);

/**
 * Decides whether to call /save-ad or /unsave-ad depending on the current class
 * of the element with the data-js-favourite attribute == the supplied id
 * @param id - the id of the job ad
 */
function updateFavourite(id) {
    let $job = $('[data-js-favourite="' + id + '"]');
    if ($job.length) {
        if (!$job.hasClass("active")) {
            $job.html(saveJobAdvert);
            fetch(`${path.host}/unsave-ad?id=${id}`, {
                method: "DELETE",
                headers: {
                    "X-CSRF-Token": $('meta[name="csrf_token"]').attr("content"),
                },
            });
        } else {
            $job.html(unsaveJobAdvert);
            fetch(`${path.host}/save-ad?id=${id}`, {
                method: "GET",
                headers: {
                    "X-CSRF-Token": $('meta[name="csrf_token"]').attr("content"),
                },
            });
        }
    }

    // if ($job.length) {
    //     if (!$job.hasClass("active")) {
    //         fragment = "unsave-ad";
    //         $job.html(saveJobAdvert);
    //     } else {
    //         $job.html(unsaveJobAdvert);
    //     }
    //     // We'll end up with a {host}/save-ad?id=XXX or {host}/unsave-ad?id=XXX
    //     let url = path.host + "/" + fragment + "?id=" + id;
    //     $.getJSON(url);
    // }
}

/**
 * When a user hits the details page, we need to determine what their intention was
 */
function initDetailsIntention() {
    if (!auth) {
        // Any clicks on the heart icons needs to redirect to the sign in page
        // with enough info to redirect back to where the user started the fav action
        $("[data-js-favourite]").on("click", function (e) {
            e.preventDefault();
            // Save the intention on a session cookie
            Cookies.set("uj_intention", "fav_" + $(this).data("js-favourite"));
            location.href = path.host + "/sign-in?after_login=" + location.pathname;
        });
        // Any clicks on the links that contain the intention == apply will set a cookie to be used later.
        $('[data-intention="apply"]').on("click", function (e) {
            Cookies.set("uj_intention", "apply_" + $("#ad_details").data("id"));
        });
        // Any clicks on the links that contain the intention == view will set a cookie to be used later.
        $('[data-intention="view"]').on("click", function (e) {
            Cookies.set("uj_intention", "view_" + $("#ad_details").data("id"));
        });
    } else if ($(".page_ad_details").length) {
        // Only init if on the ad details page
        initFavourites();
    }
}

/**
 * Set's up the appropriate events depending on whether the user is logged in or not
 * Logged in users:
 * Clicking on a heart icon will save / unsave that ad
 * Logged out users:
 * Clicking on a heart icon will redirect to the /sign-in
 */
function initFavourites() {
    // Ping /get-saved-ads to get a list of favourited ads
    checkFavourites();
    // Set click handlers on the favourite heart icons
    $("[data-js-favourite]").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        debouncedUpdateFavourite($(this).data("js-favourite"));
    });
    // Does the uj_intention cookie exist? If yes and it contains `fav_{job_id}` it means that
    // the user initially wanted to favourite a job, had to login and was redirected back
    let intention = Cookies.get("uj_intention");
    let favourites = intention ? intention.match(/^fav_(\d+)$/) : false;
    if (intention && favourites && favourites.length === 2) {
        let $job = $("[data-js-favourite=" + favourites[1] + "]");
        if ($job.length) {
            // Show it as favourited ...
            $job.toggleClass("active");
            // And call /save-ad to ensure it is favourited ...
            updateFavourite(favourites[1]);
        }
        // Get rid of the cookie if the intetion was to favourite it.
        Cookies.remove("uj_intention");
    }
}

/**
 * Pings /get-saved-ads passing in the ad id's of all the currently displayed ads
 * If it gets anything returned it ensures it shows as being favourited.
 * @returns {boolean}
 */
function checkFavourites() {
    if (!auth) {
        return false;
    }
    let jobsToCheck = [];
    $("[data-js-favourite]").each((i, el) => jobsToCheck.push($(el).data("js-favourite")));
    // Ping /get-saved-ads and pass the list of job ids (joined with a ':')
    $.ajax({
        url: path.host + "/get-saved-ads",
        cache: false,
        data: {
            ids: jobsToCheck.join(":"),
        },
    }).done((response) => {
        if (response["saved_ads"]) {
            // Iterate through all the saved ads and set them as active
            Object.keys(response["saved_ads"]).forEach((ad) => {
                $("[data-js-favourite=" + ad + "]")
                    .addClass("active")
                    .html(unsaveJobAdvert);
            });
        }
    });
}

function initSearchLink() {
    let $breadcrumb = $(".js-search-results");
    let href = $breadcrumb.attr("href");
    $breadcrumb.attr("href", href + lastQuery);
}

export { init };
