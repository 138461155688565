// import {initValidationOn, validate, validateRadio} from '../utilities/validation.js';
import { changeFrequencySuccess, genericError, unsubscribeSuccess } from "../templates/unsubscribe.js";

function init() {
    console.log("Init unsubscribe");
    let $form = $("form[name=unsubscribe]");
    $form.on("submit", e => {
        e.preventDefault();
        switch ($("[name=unsubscribe_action]:checked").val()) {
            case "stop":
                // Async call to /delete_notification_aj
                $.getJSON(path.host + "/delete_notification_aj?" + $form.serialize())
                    .done(response => {
                        if (response.code === "OK") {
                            $form.replaceWith(unsubscribeSuccess());
                        } else {
                            $form.replaceWith(genericError());
                        }
                    })
                    .fail(() => {
                        $form.replaceWith(genericError());
                    });
                break;
            case "freq":
                // Async call to /change_frequency_aj
                $.getJSON(path.host + "/change_frequency_aj?frequency=weekly&" + $form.serialize())
                    .done(response => {
                        if (response.code === "OK") {
                            $form.replaceWith(changeFrequencySuccess());
                        } else {
                            $form.replaceWith(genericError());
                        }
                    })
                    .fail(() => {
                        $form.replaceWith(genericError());
                    });
                break;
        }
    });
}

export { init };
