import * as Cookies from "js-cookie";
import DOMPurify from "isomorphic-dompurify";

export const guid = () => {
    const r = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    return r() + r() + r() + "-" + r() + "_" + r() + "-" + r() + "_" + r() + r() + r();
};

/**
 *  Receives url params and return a composed endpoint
 *  Note: Please do not blindly replace this hacky approach with new URLSearchParams()
 *  Consider that the `params` option might contain keys with undefined or empty string values
 *  and we do _not_ want these appended.
 * @param url {string} - The first part of the url
 * @param params {object} - An object of k:v pairs. If value is specified it will be appended as &k=v
 * @return {string} - A url
 */
export const getUrl = (url, params) => {
    let toReturn = url;
    let paramArray = [];
    for (let param in params) {
        // Please note that this ternary will skip a param if value is `null` or `undefined`
        // It won't skip it if its an empty string
        params[param] != null
            ? paramArray.push(`${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`)
            : false;
    }
    if (paramArray.length) {
        toReturn += "?" + paramArray.join("&");
    }
    return toReturn;
};

String.prototype.replaceAll = function (find, replace) {
    if (typeof find == "string") return this.split(find).join(replace);
    var t = this,
        i,
        j;
    while (typeof (i = find.shift()) == "string" && typeof (j = replace.shift()) == "string")
        t = t.replaceAll(i || "", j || "");
    return t;
};

export const escapeHtml = (input) => {
    return input.toString().replaceAll(["&", "<", '"'], ["&amp;", "&lt;", "&quot;"]);
};

DOMPurify.setConfig({
    ALLOWED_TAGS: ["p", "b", "a", "strong", "span", "br", "em", "h2", "h3"],
    ALLOWED_ATTR: ["target", "href", "class"],
});

export const unescapeHtml = (input) => {
    let sanitizedInput = DOMPurify.sanitize(input);
    let doc = new DOMParser().parseFromString(sanitizedInput, "text/html");
    return doc.documentElement.textContent;
};

export const convertMarkdown = (s) => {
    return escapeHtml(s)
        .replace(/(<([^>]+)>)/gi)
        .replace(/\[([^\]]+)]\(([^(]+)\)/g, "$1".link("$2"))
        .replace(/([^"])(https?:\/\/([^\s"]+))/g, "$1$3".link("$1$2"))
        .replace(/^(https?:\/\/([^\s"]+))/g, "$2".link("$1"))
        .replace(/\*\*([^*]+)\*\*/g, "<strong>$1</strong>")
        .replace(/\*([^*]+)\*/g, "<em>$1</em>")
        .replace(/^(?:###)\s*(.+?)[ \t]*$/gm, "<h3 class='govuk-heading-m'>$1</h3>")
        .replace(/\n/gi, "<br>")
        .replace(/<\/h3>+<br>/gm, "</h3>");
};

export const markdownTransform = (src) => {
    return createMarkup(convertMarkdown(src));
};

export const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
};

/**
 * Extract and return the **company id** from window.location.pathname
 *
 * Both examples below will return `4`:
 * - `/jobs/employer/4/adverts`
 * - `/employer/4/adverts` (for "/jobs"-less environments like our staging)
 * @returns {int|null} Either an integer or null
 */
export const getCompanyFromUriSegment = () => {
    let matches =
        window.location.pathname.match(/(\/jobs)?\/employer\/(\d+)/) ||
        window.location.pathname.match(/(\/jobs)?\/employer\/companies\/(\d+)/);
    if (matches && matches[2]) {
        // Return company id as an integer
        return parseInt(matches[2], 10);
    } else {
        return null;
    }
};

export const getActiveCompany = (companies = [], activeCompanyId) => {
    return Promise.all(companies.filter((company) => company.id == activeCompanyId));
};

export const isEmpty = (obj) => {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
};

export const getSearchLink = () => {
    return `${path.host}/search/${Cookies.get("uj_last_query")}`;
};

export const toUpperCaseFirst = (string) => {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Checks to see if a report returns any counts
 * @param {array} array data array with counts
 * @returns {boolean} True or false
 */
export const resultsFound = (array) => {
    let newArray = array.filter((item) => item.count != 0);
    return newArray.length > 0;
};
