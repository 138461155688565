import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function contactUsSuccess() {
    return `
<div class="alert alert-success" role="alert" role="alert">
    <h2 class="alert-heading">${t("PAGE:CONTACT_US:SUCCESS:HEADING")}</h2>
    <div>${t("PAGE:CONTACT_US:SUCCESS:TEXT")}</div>
</div>`;
}

export function contactUsError() {
    return `
<div class="error-summary" tabindex="-1" role="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:CONTACT_US:ERROR:HEADING")}</h2>
    <p class="govuk-body">${t("PAGE:CONTACT_US:ERROR:TEXT:1")}</p>
    <p class="govuk-body">${t("PAGE:CONTACT_US:ERROR:TEXT:2")}</p>
</div>`;
}
