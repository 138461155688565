import * as Cookies from "js-cookie";

// TEMP (for testing in QA) (harmless can be left in or removed)
if (window?.path?.env === "live_qa") {
    window["c"] = Cookies;
}

function initCookiesBanner() {
    let isSettingsUpdated = sessionStorage.getItem("settingsUpdated");
    // FJ-787 Accept analytics cookie
    // Has the user visited us before and have they either rejected or accepted analytics cookies?
    if (Cookies.get("seen_cookie_message") != "accept" && Cookies.get("seen_cookie_message") != "reject") {
        $("#global-cookie-message, #cookie-message").show();
        $("#show-accept").on("click", function(e) {
            e.preventDefault();
            Cookies.set("seen_cookie_message", "accept", { expires: 365 });
            // Tell session storage that we updated the our settings
            sessionStorage.setItem("settingsUpdated", true);
            // Reload the page so that the tags get loaded
            window.location = window.location.href;
        });
        $("#show-reject").on("click", function(e) {
            e.preventDefault();
            Cookies.set("seen_cookie_message", "reject", { expires: 365 });
            // Tell session storage that we updated the our settings
            sessionStorage.setItem("settingsUpdated", true);
            // Reload the page so that the tags get removed (if present)
            window.location = window.location.href;
        });
    }
    // If the user has the settingsUpdated item stored in sessionStorage the user just came from the cookies policy page
    if (isSettingsUpdated) {
        if (Cookies.get("seen_cookie_message") == "accept") {
            $("#global-cookie-message, #cookie-confirm").show();
        }
        if (Cookies.get("seen_cookie_message") == "reject") {
            $("#global-cookie-message, #cookie-reject").show();
        }
        // Make sure that the banner is in the view
        $(document).ready(function() {
            $(this).scrollTop(0);
        });
        // We remove the item from storage so that we no longer show the banner
        sessionStorage.removeItem("settingsUpdated");
    }
    // We add the on click handler here as the rejection and confirmation banner exists outside the optin mechanism
    $("#hide-reject, #hide-accept").on("click", function(e) {
        e.preventDefault();
        $("#global-cookie-message").hide();
    });
}

function initCookiesForm() {
    let $form = $("#cookie-settings-form");
    let currentValue = Cookies.get("seen_cookie_message");
    // FJ-787 Accept analytics cookie
    // The user visits the cookie-policy.html page to update their prefs
    // If the user has previously set their prefs select the correct radio button
    if (currentValue == "accept" || currentValue == "reject") {
        $form.find(`[value=${currentValue}]`).prop("checked", true);
    }
    // If no value has been selected or te value of the cookie is 'yes' or 'no' (which means it's a historic cookie)
    // we select 'no' to be the active value.
    if (!currentValue || currentValue == "yes" || currentValue == "no") {
        $form.find(`[value=reject]`).prop("checked", true);
    }
    $("#cookie-settings-form").on("submit", function(e) {
        e.preventDefault();
        let selectedValue = $form.find("input[name=analytics-settings]:checked").val();
        Cookies.set("seen_cookie_message", selectedValue, { expires: 365 });
        // Tell session storage that we updated the our settings
        sessionStorage.setItem("settingsUpdated", true);
        if (selectedValue === "reject") {
            // FJ-1689: Kill any existing GA cookies (hardcoded approach)
            Cookies.remove("_ga", { domain: ".dwp.gov.uk" });
            Cookies.remove("_ga_F1PT45TQH1", { domain: ".dwp.gov.uk" });
            Cookies.remove("_gat_UA-57523228-59", { domain: ".dwp.gov.uk" });
            Cookies.remove("_gid", { domain: ".dwp.gov.uk" });
        }
        // If the user has no referrer or the user's referrer matches the current page we refresh the page.
        // Else we redirect the user back from whence they came
        window.scrollTo(0, 0);
        if (document.referrer == "" || document.referrer == location.href) {
            window.location = window.location.href;
        } else {
            window.history.back();
        }
    });
}

export { initCookiesBanner, initCookiesForm };
