import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";
import errorSummary from "../templates/errorSummary.js";

function initValidationOn(el) {
    // Obj must be a form element
    if (el && $(el).is(":input")) {
        // If we've passed in a group of radio buttons, let's filter out everything but the first one
        // This way firing a "raiseError" event will only show the error message once
        if (el.length > 1 && el.is(":radio")) {
            el = el.filter(":first");
        }
        // Setup the raiseError, clearError custom events as well as fire clearError on keydown
        $(el).on({
            raiseError: function(e, lbl) {
                // Return null if lbl is not specified
                if (!lbl) {
                    return null;
                }
                // Set the .govuk-form-group to appear with an error state
                $(this)
                    .closest(".govuk-form-group")
                    .addClass("govuk-form-group--error");
                // Set the .govuk-input to appear with an error state
                if ($(this).is(":file")) {
                    $(this).addClass("govuk-file-upload--error");
                } else {
                    $(this).addClass("govuk-input--error");
                }
                let id = $(this).attr("id");
                // For checkboxes error span should appear as the first child inside the .govuk-form-group
                if ($(this).is(":checkbox") || $(this).is(":radio")) {
                    if (!$(`#${id}-error`).length) {
                        $(
                            `<span class="govuk-error-message" id="${id}-error"><span class="govuk-visually-hidden">${
                                window?.lang["FAJ:ERROR"]
                            }</span> ${lbl}</span>`
                        ).prependTo($(this).closest(".govuk-form-group"));
                    }
                } else {
                    // Create a p.govuk-error-message first (if needed)
                    let $errorMessage = $(`#${id}-error`);
                    if (!$errorMessage.length) {
                        let $error = $(
                            `<p class="govuk-error-message govuk-!-margin-top-0" id="${id}-error"><span class="govuk-visually-hidden">${
                                window?.lang["FAJ:ERROR"]
                            }:</span> ${lbl}</p>`
                        );
                        // Find the label for this error message
                        let $lbl = $(`label[for=${id}]`);
                        // Does a .govuk-hint follow the label? If so we'll need to append the error after the hint
                        // (Note: this is why we prefer declarative React code instead of imperative jQuery stuff folks!)
                        let $hint = $lbl.next(".govuk-hint");
                        if ($hint.length) {
                            $hint.after($error);
                        } else {
                            $lbl.after($error);
                        }
                    } else {
                        // We've already placed the p.govuk-error-message in the DOM, simply update its contents
                        $errorMessage.html(
                            `<span class="govuk-visually-hidden">${window?.lang["FAJ:ERROR"]}:</span> ${lbl}`
                        );
                    }
                }
            },
            clearError: function() {
                $(this)
                    .closest(".govuk-form-group")
                    .removeClass("govuk-form-group--error")
                    .find(".govuk-error-message")
                    .remove();
                if ($(this).is(":file")) {
                    $(this).removeClass("govuk-file-upload--error");
                } else {
                    $(this).removeClass("govuk-input--error");
                }
            }
        });
        // Checkboxes and radio buttons also clearError on click
        // if ($(el).is(":checkbox") || $(el).is(":radio")) {
        //     $(el).on("click", function () {
        //         $(this).trigger("clearError");
        //     });
        // }
        // Dropdowns clearError on change
        // if ($(el).is("select")) {
        //     $(el).on("change", function () {
        //         $(this).trigger("clearError");
        //     });
        // }
    } else {
        // Supplied object is not an input element.
        // Return false.
        return false;
    }
}

/**
 * Checks the value of a jQuery element against a rule and returns true or false
 * @param {jQuery} $el - A reference to a jQuery :input field
 * @param {string} [type] - The type of rule to validate against.
 * @return {boolean}
 */
function validate($el, type = "NOT_EMPTY") {
    switch (type) {
        case "TELEPHONE":
            return (
                $el
                    .val()
                    .search(/^([^\+\d]{2,}|^)\(?(\+44)?[\s-\(\)]{0,2}(\d[\s-\(\)]{0,2}){9,11}([^\+\d]{2,}|$)?$/) !== -1
            );
        case "EMAIL":
            return $el.val().search(/.@./i) !== -1;
        case "MAX_500":
            return $el.val().length && $el.val().length <= 500;
        case "PASSWORD_STRONG":
            // Minimum eight characters, at least one letter and one number
            return $el.val() && $el.val().replace(/\s+/g, "").length >= 8;
        default:
            return $.trim($el.val());
    }
}

/**
 * Checks if the value exists and only apply the rules if it does. Returns true (pass) or false (fail)
 * @param {jQuery} $el - A reference to a jQuery :input field
 * @param {string} [type] - The type of rule to validate against.
 * @return {boolean}
 */
function validateOptional($el, type = "NOT_EMPTY") {
    switch (type) {
        case "TELEPHONE":
            return (
                $el.val().length === 0 ||
                $el
                    .val()
                    .search(/^([^\+\d]{2,}|^)\(?(\+44)?[\s-\(\)]{0,2}(\d[\s-\(\)]{0,2}){9,11}([^\+\d]{2,}|$)?$/) !== -1
            );

        case "EMAIL":
            return $el.val().length === 0 || $el.val().search(/.@./i) !== -1;

        default:
            return $.trim($el.val());
    }
}

function validateRadio($el) {
    return $el.is(":checked");
}

/**
 * Checks to see if the supplied string validates against our password rules.
 * If not, it returns an appropriate error message taking under account all the
 * ways the string did not match expectations
 * @param pwd - the password to validate
 * @returns {undefined|string} - undefined if password is valid, an error message if not
 */
function validatePassword(pwd) {
    // Initially assume that supplied string matches all our rules (validation passes).
    let toReturn = undefined;
    if (!pwd) {
        // "Enter a password"
        toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:ERROR");
    } else {
        let hasValidLength = pwd.length >= 8 ? 1 : 0;
        let hasNumber = /\d/g.test(pwd) ? 1 : 0;
        let hasLetter = /[a-z]/gi.test(pwd) ? 1 : 0;
        if (!hasValidLength || !hasNumber || !hasLetter) {
            switch (hasValidLength.toString() + hasNumber.toString() + hasLetter.toString()) {
                case "000":
                case "010":
                    // "Password must be 8 characters or more and contain at least one letter"
                    toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:FE_RULE:LENGTH_AND_LETTER");
                    break;
                case "011":
                    // "Password must be 8 characters or more"
                    toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:FE_RULE:LENGTH");
                    break;
                case "001":
                    // "Password must be 8 characters or more and contain at least one number"
                    toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:FE_RULE:LENGTH_AND_NUMBER");
                    break;
                case "100":
                    //"Password must contain at least one letter and number";
                    toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:FE_RULE:LETTER_AND_NUMBER");
                    break;
                case "110":
                    // "Password must contain at least one letter"
                    toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:FE_RULE:LETTER");
                    break;
                case "101":
                    // "Password must contain at least one number"
                    toReturn = t("PAGE:CREATE_ACCOUNT:PASSWORD:FE_RULE:NUMBER");
                    break;
            }
        }
    }
    return toReturn;
}

export { initValidationOn, validate, validateOptional, validateRadio, validatePassword };
