import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function unsaveJobAdvert() {
    return `
    <i class="icon icon-heart-full govuk-!-margin-right-1"></i>${t("PAGE:DETAILS:RHS:LINK:UNSAVE")}
  `;
}

export function saveJobAdvert() {
    return `
    <i class="icon icon-heart-blue govuk-!-margin-right-1"></i>${t("PAGE:DETAILS:RHS:LINK:SAVE")}
  `;
}
