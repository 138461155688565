import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

const errorSummary = (errors = []) => {
    const listItems = errors.map(({ id, error, noLink = false }) => {
        let toReturn = "<li>";
        if (noLink) {
            toReturn += `<span class="error">${error}</span>`;
        } else {
            toReturn += `<a id="${id}-link" href="#${id}">${error}<a/>`;
        }
        toReturn += "</li>";
        return toReturn;
    });
    return `
        <div class="govuk-error-summary" tabindex="-1">
            <div role="alert">
                <h1 class="govuk-error-summary__title">${t("FAJ:ERROR:SUMMARY:TITLE")}</h1>
                <div class="govuk-error-summary__body">
                    <ul class="govuk-list govuk-error-summary__list">${listItems.join("")}</ul>
                </div>
            </div>
        </div>
    `;
};

export default errorSummary;
