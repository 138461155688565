// Utilities
import * as authentication from "./utilities/authentication.js";
import * as analyticsSettings from "../common/utilities/analyticsSettings.js";
import { convertMarkdown } from "../common/utilities/util.js";
import { getParamByName } from "../common/utilities/queryString.js";
import initInactivityTimer from "../auth/inactivityTimer.js";
import { initGeosuggest } from "./utilities/geosuggest.js";

// Page-specific imports
import * as search from "./pages/search.js";
import * as details from "./pages/details.js";
import * as unsubscribe from "./pages/unsubscribe.js";
import * as contact from "./pages/contact.js";
import * as apply from "./pages/apply.js";
import * as report from "./pages/report.js";
import * as advanceSearch from "./pages/advanceSearch.js";

// Bootstrap the Find a job javascript functionality
const bootstrap_uj = function () {
    // FJ-1702: Redirect old /sign-in?employer=1 to new /employer/sign-in
    if ($(".page_sign_in").length && getParamByName("employer")) {
        window.location = `${path.host}/employer/sign-in`;
    }
    // FJ-1700: Redirect old /create-account?employer=1 to new /employer/create-account
    if ($(".page_create_account").length && getParamByName("employer")) {
        window.location = `${path.host}/employer/create-account`;
    }

    // FJ-787 Accept analytics cookie
    analyticsSettings.initCookiesBanner();

    // Check whether user is logged in and init login and registration forms (if needed)
    authentication.init();

    // Per-page initialisation
    if ($(".page_search").length) {
        search.init();
    }

    if ($(".page_ad_details").length) {
        details.init();
    }

    if ($(".page_report_an_ad").length) {
        report.init();
    }

    if ($(".page_unsubscribe").length) {
        unsubscribe.init();
    }

    if ($(".page_contact").length) {
        contact.init();
    }

    if ($("form[name=apply]").length) {
        apply.init();
    }

    if ($(".page_advanced_search").length) {
        advanceSearch.init();
    }

    // FJ-787 Accept analytics cookie
    if ($(".page-cookies_policy").length) {
        analyticsSettings.initCookiesForm();
    }

    // Homepage js can reside here for now
    // Should move into its own file if it grows
    if ($(".page_home").length) {
        let $latestNews = $(".latest-news");
        $latestNews.find("div").html(convertMarkdown($latestNews.find("div").text()));
        $latestNews.fadeIn(400);
    }

    // Showing / hiding the header menu (on mobile)
    $(".govuk-header-auth-toggle").on("click", (e) => {
        e.preventDefault();
        $(".govuk-header-auth").toggleClass("visible");
    });

    // Any a.reset-link elements inside forms should reset them
    $("form a.reset-link").on("click", function (e) {
        e.preventDefault();
        // Reset the text inputs in the form
        $(this).closest("form").find("input:text, textarea").val("");
        // Now reset the radio and dropdowns to their default value
        // Reset the distance dropdown menu
        $("#adv_dist").val("");
        // Reset results per page
        $("#adv_per_page").val("10");
        // Reset sort by
        $("#adv_sorting option:eq(0)").prop("selected", true);
        // Reset the DC checkbox
        $("#adv_disability_confident").prop("checked", false);
        // Reset salary from
        $("#adv_sf").val("");
        // Reset salary to
        $("#adv_st").val("");
        // Reset category
        $("#adv_cat").val("");
        // Reset contract type radio
        $("#adv_contract_type_any").prop("checked", true);
        // Reset contract type radio
        $("#adv_contract_time_any").prop("checked", true);
        // Reset upload freshness
        $("#adv_freshness_any").prop("checked", true);
    });

    // All input fields with the data-js="geosuggest" attribute need a Geosuggest autocomplete
    initGeosuggest();

    // When submitting a form[name=search], if the "where" field has loc_id data
    // (i.e. the user has picked a location from the autocomplete and we have the exact id)
    // use the ?loc= parameter instead of ?w=
    $("form[name=search]").on("submit", function () {
        // Store a reference to the #where field
        let $w = $(this).find("#where");

        // Extract its value (and trim spaces)
        let w = $.trim($w.val());

        // Initially assume that we will not need to create the `remote` hidden field
        let remote = false;

        // Prep location-specific regexps to match against
        let remoteRegexp = /remote working|fully remote|remote/i;
        let hybridRegexp = /hybrid working|hybrid remote|hybrid/i;
        if (path?.lang_code === "cy") {
            remoteRegexp = /yn gyfan gwbl o bell|gweithio o bell|o bell/i;
            hybridRegexp = /hybrid o bell|gweithio hybrid|hybrid/i;
        }

        if ($w.data("remote")) {
            // User picked one of the "remote" or "hybrid" entries from the autocomplete and the "remote"
            // data attribute of the field is populated (geosuggest.js does all that)
            // Remove the `name` attribute of the where field so that its contents
            // are not submitted with the `w` url param
            $w.removeAttr("name");
            remote = $w.data("remote");
        } else if (/hybrid/i.test(w)) {
            // User has typed in something with word "hybrid" in it:
            // 1. Set the remote filter
            remote = "hybrid";
            // 2. Strip any of the three "hybrid"-related sentences we find
            w = $.trim(w.replace(hybridRegexp, ""));
            $w.val(w);
        } else if (/remote|o bell/i.test(w)) {
            // User has typed something with the word "remote" in it:
            // 1. Set the remote filter
            remote = "fully_remote";
            // 2. Strip any of the three "remote"-related sentence we find
            w = $.trim(w.replace(remoteRegexp, ""));
            $w.val(w);
        }

        if (remote) {
            // remote var is not false. This means we should also pass in the "remote" url param
            // (we do this via creating (or updating if already created) a hidden field
            let $remote = $(this).find("input[name=remote]");
            if ($remote.length) {
                // Hidden field has already been created, let's just update its value
                $remote.val(remote);
            } else {
                // Hidden field is not present, create it
                $(`<input type="hidden" name="remote" value="${remote}" />`).appendTo($(this));
            }
        } else if ($w.data("loc_id")) {
            // User picked one location from the autocomplete.
            // We won't need the `w` url param so we'll remove the name attribute from the where field
            $w.removeAttr("name");
            // Then let's create a hidden field with the name `loc` and the loc_id
            // (provided it's not already created)
            let $loc = $(this).find("input[name=loc]");
            if ($loc.length) {
                // Hidden field has already been created, let's just update it's value
                $loc.val($w.data("loc_id"));
            } else {
                // Hidden field is not present, create it
                $(`<input type="hidden" name="loc" value="${$w.data("loc_id")}" />`).appendTo($(this));
            }
        }
        // FJ-330 - Add an exception on the search results page
        // That does not clear the dc filters when performing a new search
        // TODO: Remove this / reconsider this once the disability confident link on homepage is removed.
        if (getParamByName("dc") && $(".page-search").length) {
            $(`<input type="hidden" name="dc" value="1" />`).appendTo($(this));
        }
    });

    // Do not allow user to set a salary_from larger than salary_to
    // (search results / adv search)
    $("#sf").on("change", function () {
        let salary_from = parseInt($("#sf").val(), 10),
            salary_to = parseInt($("#st").val(), 10);
        if (salary_to && salary_from > salary_to) {
            $("#st").val("");
        }
    });
    $("#st").on("change", function () {
        let salary_from = parseInt($("#sf").val(), 10),
            salary_to = parseInt($("#st").val(), 10);
        if (salary_from && salary_to && salary_to < salary_from) {
            $("#sf").val("");
        }
    });

    // [data-js=print] buttons need to call window.print()
    $("[data-js=print]").on("click", function (e) {
        e.preventDefault();
        window.print();
    });

    // Add an event listener to all <details> elements for the 'toggle' event
    $("details").on("toggle", function () {
        // Update the 'ariaExpanded' property of the <summary> element based
        // on the open state of the <details> element
        $(this).children("summary").prop("ariaExpanded", $(this).get(0).open);
    });

    initInactivityTimer();
};

$(bootstrap_uj);
