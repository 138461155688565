import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function emailAlertCreation() {
    return `
<div class="email-alert-signed-in">
    <p class="govuk-body">${t("PAGE:SEARCH:ALERT_BOX:CREATE:GENERIC")}</p>
    <button class="govuk-button govuk-!-margin-bottom-0" type="submit" data-cy="submit">${t(
        "PAGE:SEARCH:ALERT_BOX:CREATE:BUTTON",
    )}</button>
</div>`;
}

export function emailAlertSuccess(searchTitle, email) {
    return `
<div class="email-alert-box-success" role="alert" data-cy="alert">
    ${t("PAGE:SEARCH:ALERT_BOX:SUCCESS:GENERIC", `<strong>${email}</strong>`)}
</div>`;
}

export function emailAlertError() {
    return `
<div class="email-alert-box-error" role="alert">
    <strong>${t("PAGE:SEARCH:ALERT_BOX:ERROR:1")}</strong><br/>
    ${t("PAGE:SEARCH:ALERT_BOX:ERROR:2", `<a class="govuk-button" href="${path.host}/contact-us">`, `</a>`)}
</div>`;
}
