import { initValidationOn, validate } from "../utilities/validation.js";
import { getSearchLink } from "../../common/utilities/util.js";
import {
    applicationSuccess,
    applicationErrors,
    uploadErrors,
    uploadInProgress,
    uploadSuccess
} from "../templates/apply.js";
import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";
import axios from "axios";
import errorSummary from "../templates/errorSummary.js";

/** The number in milliseconds between consecutive calls to /poll */
const cvPollInterval = 2000;

/** Max number of poll attempts we can make before raising an error */
const cvPollMaxAttempts = 5;

// jQuery references to all the form fields
const $applicationForm = $("form[name=apply]");
const $fullName = $("#full_name");
const $message = $("#message");
const $cvId = $("#cv_id");
const $cvUpload = $("#cv_upload");

// Given the async nature of this form (cvs get uploaded on change, fields get validated on submit, errors might
// occur in any of these two states) and the GDS requirement to keep the _order_ of error messages consistent,
// we'll create a formErrors "state" variable that we'll be updating from various places.
// showErrorSummary() method will be looking at this to generate the appropriate DOM whenever we call it.
const formErrors = {
    full_name: false,
    message: false,
    // Errors that should appear above the "CV to apply with" <select>
    cv_id: false,
    // Errors that will appear above the "Upload a CV" <input type="file"> picker
    cv_upload: false
};

/**
 * Initialises the Javascript functionality of the /apply/{ad_id} page
 */
function init() {
    // Load user's CVs
    fetchCVs();

    // This adds the last search to the breadcrumbs search link
    $(".js-search-results").attr("href", getSearchLink());

    // If user chooses an already-uploaded CV via the #cv_id dropdown,
    // the #cv_upload file picker should reset (along with any errors for it)
    $cvId.on("change", function() {
        if ($(this).val()) {
            $cvUpload.val("");
            $cvUpload.trigger("clearError");
            if (formErrors.cv_upload) {
                formErrors.cv_upload = false;
                showErrorSummary();
            } else {
                formErrors.cv_upload = false;
            }
        }
    });

    // If a new CV has just been selected from the file picker, proceed with upload
    $cvUpload.on("change", async function() {
        // Proceed with the logic only if user picked a file to update (and did not press "cancel" for example)
        if (!$cvUpload.val()) {
            // If picker has an empty value most probably user tapped the "Cancel" button
            // Clear any errors
            $cvUpload.trigger("clearError");
            formErrors.cv_upload = false;
            showErrorSummary();
        } else {
            // Show the "Upload in progress" message
            $(".govuk-form-group-upload")
                .hide()
                .before(uploadInProgress());
            // Hide the "CV uploaded successfully" green box (if present)
            $(".success-summary").remove();
            try {
                const response = await uploadCv();
                if (response?.data?.cv_uid) {
                    // console.log("Start polling with", response.data.cv_uid);
                    try {
                        const pollResponse = await pollCv(response.data.cv_uid);
                        // Add the filename and cv_id of the just-uploaded cv to the end of $cvId
                        $cvId.append(
                            `<option value="${pollResponse.cv.id}" selected="selected">${
                                pollResponse.cv.filename
                            }</option>`
                        );
                        formErrors.cv_upload = false;
                        $cvUpload.trigger("clearError");
                        // Since we've added the just-uploaded cv to the list of cvs (and selected it), we will
                        // clear the file upload - just in case any other validation fails
                        $cvUpload.val("");
                        // Re-show the file picker and show a success message
                        $(".govuk-form-group-upload")
                            .show()
                            .before(uploadSuccess());
                    } catch (error) {
                        console.log("pollCv error:", error);
                    }
                }
            } catch (error) {
                // We hope the BE has returned some structured JSON to describe the error
                let errorKey = error?.response?.data?.error;
                if (error.status === 413) {
                    // Edge case with `file_too_large` error type,
                    // BE will not return a correctly-structured JSON
                    // payload so we'll hardcode the key
                    // FIXME: Ask John to return a JSON payload for 413?
                    errorKey = "file_too_large";
                }
                if (!errorKey) {
                    // We know we have an error but cannot match it to any of the specified error_types.
                    // Show the "The selected file could not be uploaded - try again" error
                    errorKey = "missing_cv";
                }
                formErrors.cv_upload = uploadErrors[errorKey];
                $cvUpload.trigger("raiseError", formErrors.cv_upload);
                $(".govuk-form-group-upload").show();
                showErrorSummary();
            } finally {
                $(".in-progress-summary").remove();
            }
        }
    });

    // Set up validation events
    initValidationOn($fullName);
    initValidationOn($message);
    initValidationOn($cvId);
    initValidationOn($cvUpload);

    // Handle form submission
    $applicationForm.on("submit", async e => {
        e.preventDefault();

        // We'll default to the assumption that validation was successful
        let proceed = true;

        // Validate full name
        if (!validate($fullName)) {
            proceed = false;
            formErrors.full_name = t("PAGE:APPLY:FULL_NAME:ERROR");
            $fullName.trigger("raiseError", formErrors.full_name);
        } else {
            formErrors.full_name = false;
            $fullName.trigger("clearError");
        }

        // Validate message
        if (!validate($message)) {
            proceed = false;
            formErrors.message = t("PAGE:APPLY:MESSAGE:ERROR");
            $message.trigger("raiseError", formErrors.message);
        } else {
            formErrors.message = false;
            $message.trigger("clearError");
        }

        // Validate CV choice
        if (!$cvId.val()) {
            proceed = false;
            // We need a CV to be selected before we proceed
            formErrors.cv_id = t("PAGE:APPLY:CV:CHOOSE:ERROR");
            $cvId.trigger("raiseError", formErrors.cv_id);
        } else {
            formErrors.cv_id = false;
            $cvId.trigger("clearError");
        }

        if (Object.values(formErrors).some(error => error !== false)) {
            // If we have at least 1 value inside `formErrors` that is not false (i.e. we have at least one error)
            showErrorSummary();
        } else if (proceed) {
            hideErrorSummary();
            // Proceed with the submission
            axios
                .post(
                    `${path.host}/submit_application`,
                    {
                        ad_id: $("[name=ad_id]").val(),
                        full_name_c: $fullName.val(),
                        cv_id: $cvId.val(),
                        message_c: $message.val(),
                        cc_self: $("#cc_self:checked").val() ? 1 : 0
                    },
                    {
                        headers: { "content-type": "application/x-www-form-urlencoded" }
                    }
                )
                .then(response => {
                    if (response.data.status === "ok") {
                        // All is solid, application is done!
                        $applicationForm.replaceWith(applicationSuccess());
                    } else {
                        let meta = {
                            length: response.data.error === "message_too_long" ? $message.val().length : null
                        };
                        $applicationForm.prepend(applicationErrors(response.data.error, meta));
                    }
                    // When the ajax call finishes we set the x y axis to 0
                    // So that we move to the top of the page
                    window.scrollTo(0, 0);
                })
                .catch(error => {
                    console.log("/submit_application error", error);
                    $applicationForm.prepend(applicationErrors(error));
                });
        }
    });
}

function showErrorSummary() {
    // Convert the formErrors object to an errors[] array that `errorSummary()` can work with
    let errors = Object.entries(formErrors)
        .filter(([key, value]) => value !== false)
        .map(([key, value]) => ({
            id: key,
            error: value
        }));
    if (errors.length > 0) {
        if ($("#errorSummaryCanvas").length === 1) {
            $("#errorSummaryCanvas").replaceWith(errorSummary(errors));
        } else if ($(".govuk-error-summary").length === 1) {
            $(".govuk-error-summary").replaceWith(errorSummary(errors));
        }
        $(".govuk-error-summary").focus();
    } else {
        hideErrorSummary();
    }
}

function hideErrorSummary() {
    $(".govuk-error-summary").replaceWith('<div id="errorSummaryCanvas"></div>');
}

/**
 * POSTS to /upload-cv with the file picked by the user.
 * Also ensures that the correct X-CSRF-Token header is sent.
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
async function uploadCv() {
    let fd = new FormData();
    fd.append("cv_c", $cvUpload.get(0).files[0]);
    return axios.post(`${path.host}/upload-cv`, fd, {
        headers: {
            "X-CSRF-Token": $('meta[name="csrf_token"]').attr("content")
        }
    });
}

/**
 * Polls with a GET to /upload-cv to see if the just-uploaded CV has been saved to the DB (and therefore has a cv_id)
 * @param cv_uid The temporary id returned by the backend after a successfull call to /upload-cv
 * @param attempts how many times we've pinged /upload-cv
 */
async function pollCv(cv_uid, attempts = 0) {
    console.log(`polling for ${cv_uid}, attempt ${attempts}`);
    if (attempts > cvPollMaxAttempts) {
        throw new Error("Maximum number of attempts reached");
    }
    try {
        const response = await axios.get(`${path.host}/upload-cv?cv_uid=${cv_uid}`);
        if (response.data.status === "ok") {
            return response.data;
        } else if (response.data.status === "in_progress") {
            // Wait for the cvPollInterval
            await new Promise(resolve => setTimeout(resolve, cvPollInterval));
            return pollCv(cv_uid, attempts + 1);
        }
    } catch (error) {
        throw new Error(error.message);
    }
}

/**
 * Calls /my-cvs to return the list of CVs for the currently logged in user
 * and updates the #cv_id <select> with the returned CVs
 */
function fetchCVs() {
    axios
        .get(`${path.host}/my-cvs`)
        .then(response => {
            let html = `<option value="">${t("PAGE:APPLY:CV:CHOOSE:0")}</option>`;
            if (response.data.cvs.length) {
                response.data.cvs.forEach(cv => {
                    html += `<option value="${cv.id}">${cv.filename}</option>`;
                });
            }
            $cvId.html(html);
        })
        .catch(error => {
            // TODO: What do we do if /my-cvs fails? The application process cannot proceed. hide everything?
            console.error("Could not fetch!", error.toJSON());
        });
}

export { init };
