import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function jobseekerLoggedIn(email, isEmployer = false) {
    // FJ-2107: Commented out: <li><span class="header-auth-greeting">${t("NAVBAR:LOGGED_IN:WELCOME", `<strong>${email}</strong>`)}</span></li>
    return `<li><a class="govuk-link" href="${path.host}/your-account">${t("NAVBAR:LOGGED_IN:YOUR_ACCOUNT")}</a></li>
    <li><a class="govuk-link" href="${path.host}/sign-out">${t("NAVBAR:LOGGED_IN:SIGN_OUT")}</a></li>
    <li><a class="govuk-link" href="${path.host}/employer${isEmployer ? "/dashboard" : ""}">${t(
        "NAVBAR:LOGGED_IN:EMPLOYERS",
    )}</a></li>`;
}

export function employerLoggedIn(email) {
    return `<li><a class="govuk-link" href="${path.host}/employer/your-account" data-js="emp-account-link">${t(
        "NAVBAR:LOGGED_IN:YOUR_ACCOUNT",
    )}</a></li>
    <li><a class="govuk-link" href="${path.host}/employer/sign-out">${t("NAVBAR:LOGGED_IN:SIGN_OUT")}</a></li>
    <li><a class="govuk-link" href="${path.host}">${t("NAVBAR:JOBSEEKERS")}</a></li>`;
}
