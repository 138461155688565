import { checkLoginStatus } from "../jobseeker/utilities/authentication.js";
import A11yDialog from "a11y-dialog";
import throttle from "lodash.throttle";
import { getParamByName } from "../common/utilities/queryString.js";
import { getAppAuthToken, setAppAuthCookie } from "../actions/authActions.js";

/**
 * Sensible defaults for the inactivity timer.
 * An "inactive" user is defined as one who has not moved their mouse or clicked or typed on their keyboard
 * @type {object}
 * @property {number} secondsUntilWarning - Number of seconds a user can be inactive before we show the warning modal
 * @property {number} secondsUntilSignOut - Number of seconds a user can be inactive before we sign them off
 * @property {number} timerInterval - Number of milliseconds for the timer tick
 * @property {number} keepAliveInterval - Number of milliseconds before we'll /ping the BE to keep the session alive
 */
const inactivityTimerPreferences = {
    secondsUntilWarning: 780, // At 13 minutes we'll show the warning
    secondsUntilSignOut: 900, // At 15 minutes we'll log the user out
    timerInterval: 1000,
    keepAliveInterval: 840000 // Every 14 minutes we'll /ping
};

// If we're on Support, we'll change the timings to:
// 58 minutes to warning and 60 minutes to /sign-out
if (window?.path?.portal === "support") {
    inactivityTimerPreferences.secondsUntilWarning = 3480;
    inactivityTimerPreferences.secondsUntilSignOut = 3600;
}

// Allow overrides for inactivityTimerPreferences if the `inactivity_seconds` url parameter is present
// E.g. for https://dev.adujtest.co.uk/?inactivity_seconds=20 the timer will sign user out at 20 seconds and will
// show the inactivity modal at half that time (10 seconds).
let overrides = getParamByName("inactivity_seconds");
if (overrides && /^\d+$/.test(overrides)) {
    overrides = parseInt(overrides, 10);
    inactivityTimerPreferences.secondsUntilSignOut = overrides;
    inactivityTimerPreferences.secondsUntilWarning = Math.floor(overrides / 2);
}

/**
 * Will hold a reference to the timer returned by setInterval
 * (useful for cancelling it)
 * @type {number} - timer identifier
 */
let inactivityTimer = 0;

/**
 * Main initialisation function for the inactivity timer.
 * (Imported from jobseeker.js, employer/bootstrap.js and support/boostrap.js)
 */
const initInactivityTimer = () => {

    // If we're not on LIVE and ?show_inactivity_modal=1,
    // simply show the modal and return (helps with a11y testing)
    if (window?.path?.env && window?.path?.env !== "live" && getParamByName("show_inactivity_modal")) {
        window["fjInactivityModal"] = new A11yDialog(document.getElementById("inactivity-modal"));
        window["fjInactivityModal"].show();
        return;
    }

    timerLog("initInactivityTimer");
    // Skip initialisation if we're on the /sign-out page
    if (window.location.pathname === "/sign-out") {
        timerLog("We're on the /sign-out page, skipping initialisation of inactivity timer");
        return;
    }
    // Establish if we have a valid user session (if not, skip initialisation)
    let sessionInfo = checkLoginStatus();
    if (sessionInfo === false) {
        timerLog("We don't have a valid user session, skipping initialisation of inactivity timer");
        return;
    }
    // This script relies on localStorage as the mechanism to share last user activity info between tabs.
    // If the current browser environment does not support it, return false
    if (!window?.localStorage) {
        timerLog("No window.localStorage present. Skipping initialisation of inactivity timer");
        return;
    }

    // We have a valid user session. Store sessionInfo.timestamp in localStorage,
    // with a key of "lastActivity". This will be shared across tabs
    localStorage.setItem("lastActivity", sessionInfo.timestamp);

    // Monitor all mouse mouse / click / keydown events on document object
    // and fire a (throttled) updateLastActivity method
    $(document).on("mousemove.inactivity click.inactivity keydown.inactivity", throttledActivity);

    // Start the inactivity timer
    inactivityTimer = setInterval(sessionTimer, inactivityTimerPreferences.timerInterval);

    // Start the session keepalive timer (keepAliveInterval defaults to 14 minutes)
    setInterval(ping, inactivityTimerPreferences.keepAliveInterval);
};

const sessionTimer = () => {
    // Get current timestamp
    let now = currentTime();
    // Load the lastActivity from localStorage
    let lastActivity = parseInt(localStorage.getItem("lastActivity"), 10);
    if (!lastActivity || isNaN(lastActivity)) {
        lastActivity = now;
        localStorage.setItem("lastActivity", now);
    }
    // Main loop
    if (now > lastActivity + inactivityTimerPreferences.secondsUntilSignOut) {
        // Time if up. Sign user out.
        timerLog("Signing user out...");
        // Stop timer
        clearInterval(inactivityTimer);
        // Remove the inactivity event listeners from document
        $(document).off(".inactivity");
        // Redirect user to /sign-out
        let redirectTo = `${path.host}/sign-out?reason=inactivity`;
        if (window?.path?.portal === "support") {
            redirectTo += "&portal=support";
        }
        window.location = redirectTo;
    } else if (now > lastActivity + inactivityTimerPreferences.secondsUntilWarning) {
        // Show the inactivity modal (and instantiate if needed)
        if (!window["fjInactivityModal"]) {
            window["fjInactivityModal"] = new A11yDialog(document.getElementById("inactivity-modal"));
        }
        // Update the seconds counter inside the modal
        updateCountdownTimer(lastActivity + inactivityTimerPreferences.secondsUntilSignOut - now);
        // Show the modal
        window["fjInactivityModal"].show();
        timerLog(`${lastActivity + inactivityTimerPreferences.secondsUntilSignOut - now} seconds until sign-out`);
    } else {
        // Hide modal (if showing) and keep on ticking
        if (window["fjInactivityModal"]) {
            window["fjInactivityModal"].hide();
            // Let's also clear the countdown inside the modal
            $("#inactivity-timer").text("");
        }
        timerLog(`${lastActivity + inactivityTimerPreferences.secondsUntilSignOut - now} seconds until sign-out`);
    }
};

/**
 * Returns a Perl-compatible timestamp (seconds instead of milliseconds which is the default in JS)
 * Helps with comparing against `lastActivity`
 * @returns {number} - Current time in seconds
 */
const currentTime = () => Math.floor(Date.now() / 1000);

/**
 * Updates the `lastActivity` localStorage item with the current timestamp
 */
const updateLastActivity = () => {
    timerLog("Activity detected, updating lastActivity in localStorage");
    localStorage.setItem("lastActivity", currentTime());
};
const throttledActivity = throttle(updateLastActivity, 2000);

/**
 * Updates the seconds countdown that is shown inside the inactivity modal
 * Has a bit of logic to avoid the "countdown briefly resets before modal is hidden"
 * @param seconds
 */
const updateCountdownTimer = seconds => {
    let $countdown = $("#inactivity-timer");
    if ($countdown.length) {
        let previousTimeRemaining = $countdown.data("time-remaining");
        $countdown.data("time-remaining", seconds);
        // Only update the countdopwn if the value we're about to show is smaller than the existing one
        // (otherwise the modal is probably about to be hidden)
        if (!previousTimeRemaining || seconds <= previousTimeRemaining) {
            $countdown.text(`${seconds} ${path.lang_code === "cy" ? "eiliad" : " seconds"}`);
        }
    }
};

/**
 * Wrapper for console.log, but only if ?debug_inactivity_timer url param is present
 * @param {string} text
 */
const timerLog = message => {
    if (getParamByName("debug_inactivity_timer")) {
        console.log(message);
    }
};

/**
 * Irrespective of what we want to do in the FE, the BE will keep a session alive
 * for a max of 15 minutes. We'll arbitrarily /ping the BE in order to keep alive
 * our session every 14 minutes (keepAliveInterval / 840 seconds)
 */
const ping = () => {
    timerLog("Async call to /ping to keep the session alive...");
    $.ajax({
        url: `${path.host}/ping?_ie_cachebust=${Math.random()}`,
        type: "GET",
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    }).done(() => {
        // If the user is an employer or support user we'll also need to
        // /ping these apps
        if (window?.path?.portal) {
            timerLog(`Async call to ${path.api}ping as well to keep the session alive...`);
            $.ajax({
                url: `${path.api}ping?_ie_cachebust=${Math.random()}`,
                type: "GET",
                beforeSend: function(xhr) {
                    xhr.setRequestHeader("Authorization", `Bearer ${getAppAuthToken()}`);
                }
            }).done(response => {
                setAppAuthCookie(response.auth.token);
            });
        }
    });
};

export default initInactivityTimer;
