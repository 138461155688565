import { initValidationOn, validate, validateOptional, validateRadio } from "../utilities/validation.js";
import { contactUsSuccess, contactUsError } from "../templates/contact.js";
import { checkLoginStatus } from "../utilities/authentication.js";
import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";
import errorSummary from "../templates/errorSummary.js";

function init() {
    let $contactForm = $("form[name=contact]");
    if ($contactForm.length) {
        initValidationOn($("#email"));
        initValidationOn($("#telephone"));
        initValidationOn($("#notes"));
        initValidationOn($("[name=reason]"));

        $contactForm.on("submit", e => {
            e.preventDefault();
            let proceed = true,
                $email = $("#email"),
                $notes = $("#notes"),
                $telephone = $("#telephone"),
                $reason = $("input[name=reason]"),
                $melikoupa = $(".melikoupa input"),
                errors = [];

            // Before we do anything else, let's clear any box error boxes
            $(".govuk-error-summary").remove();

            if (!validate($email, "EMAIL")) {
                proceed = false;
                $email.trigger("raiseError", [t("PAGE:CONTACT_US:EMAIL:ERROR")]);
                errors.push({ id: "email", error: t("PAGE:CONTACT_US:EMAIL:ERROR") });
            }
            if (!validateOptional($telephone, "TELEPHONE")) {
                proceed = false;
                $telephone.trigger("raiseError", [t("PAGE:CONTACT_US:TELEPHONE:ERROR")]);
                errors.push({ id: "telephone", error: t("PAGE:CONTACT_US:TELEPHONE:ERROR") });
            }
            if (!validateRadio($reason)) {
                proceed = false;
                $reason.trigger("raiseError", [t("PAGE:CONTACT_US:REASON:ERROR")]);
                errors.push({ id: "reason-1", error: t("PAGE:CONTACT_US:REASON:ERROR") });
            }
            if (!validate($notes)) {
                proceed = false;
                $notes.trigger("raiseError", [t("PAGE:CONTACT_US:MORE_DETAILS:ERROR")]);
                errors.push({ id: "notes", error: t("PAGE:CONTACT_US:MORE_DETAILS:ERROR") });
            }
            if (proceed) {
                $.ajax({
                    url: path.host + "/send_feedback",
                    type: "POST",
                    cache: false,
                    data: {
                        contact_email: $email.val(),
                        contact_message_c: $notes.val(),
                        telephone: $telephone.val(),
                        reason: $reason.filter(":checked").val(),
                        // Add the honeypot
                        [$melikoupa.prop("name")]: $melikoupa.val()
                    }
                })
                    .done(response => {
                        if (response.code === "OK") {
                            $contactForm.replaceWith(contactUsSuccess());
                        } else {
                            $contactForm.before(contactUsError());
                        }
                    })
                    .fail(() => {
                        $contactForm.before(contactUsError());
                    });
            } else {
                // The user has validation errors and we need to update the view with an error sumary and new document title
                if (!document.title.includes(t("FAJ:ERROR"))) {
                    document.title = `${t("FAJ:ERROR")} ${document.title}`;
                }
                $("#errorSummary").before(errorSummary(errors));
                $(".govuk-error-summary").focus();
            }
        });

        // If user is logged in, let's prepopulate the email address and make it non-editable
        let auth = checkLoginStatus();
        if (auth) {
            console.log(auth);
            $("#email")
                .val(auth.email)
                .attr("disabled", true);
        }
    }
}

export { init };
