import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function registrationSuccess(email) {
    return `
<div class="alert alert-success" role="alert" data-cy="alert">
    <h2 class="alert-heading">${t("PAGE:CREATE_ACCOUNT:SUCCESS:HEADING")}</h2>
    <p class="govuk-body">${t("PAGE:CREATE_ACCOUNT:SUCCESS:TEXT:1")}</p>
    <p class="govuk-body"><strong>${t("PAGE:CREATE_ACCOUNT:SUCCESS:TEXT:2", email)}</strong></p>
    <p class="alert-text">${t("PAGE:CREATE_ACCOUNT:SUCCESS:TEXT:3")}</p>
</div>`;
}

export function employerRegistrationSuccess(email) {
    if (path.lang_code === "en") {
        return `
<div class="govuk-panel govuk-panel--confirmation">
    <h1 class="govuk-panel__title">
        Check your inbox
    </h1>
</div>
<p class="govuk-body">
    <strong>An email has been sent to <span>${email}</span></strong>
</p>
<p class="govuk-body">It contains a link that will allow you to verify your account.</p>
<p class="govuk-body">
    Check your inbox and follow the link to finish creating your Find a job account. 
    This link will expire in <strong>30 minutes</strong>.
</p>
<h2 class="govuk-heading-m">Didn't receive your email?</h2>
<p class="govuk-body">If you haven’t received an email from us, please follow the suggestions below:</p>
<ol class="govuk-list govuk-list--number">
    <li>Wait a few minutes - sometimes emails can take some time to come through.</li>
    <li>Check your spam/junk folder. If it is there, mark it as ‘not spam’ and click the link.</li>
    <li>Check that you registered with the correct email address.</li>
    <li>
        If the above steps do not help, you can
        <a href="${path.host}/contact-us" class="govuk-link">contact us</a>.
    </li>
</ol>`;
    } else if (path.lang_code === "cy") {
        return `
<div class="govuk-panel govuk-panel--confirmation">
    <h1 class="govuk-panel__title">
        Gwiriwch eich mewnflwch
    </h1>
</div>
<p class="govuk-body">
    <strong>Mae e-bost wedi'i anfon at <span>${email}</span></strong>
</p>
<p class="govuk-body">Mae'n cynnwys dolen a fydd yn caniatáu i chi wirio'ch cyfrif.</p>
<p class="govuk-body">
    Gwiriwch eich mewnflwch a dilynwch y ddolen i orffen creu eich cyfrif Dod o Hyd i Swydd. 
    Bydd y ddolen hon yn dod i ben mewn <strong>30 munud</strong>.
</p>
<h2 class="govuk-heading-m">Heb dderbyn eich e-bost?</h2>
<p class="govuk-body">Os nad ydych wedi derbyn e-bost gennym, dilynwch yr awgrymiadau isod:</p>
<ol class="govuk-list govuk-list--number">
    <li>Arhoswch ychydig funudau - weithiau gall e-byst gymryd peth amser i ddod drwodd.</li>
    <li>Gwiriwch eich ffolder sbam/sothach. Os yw yno, marciwch ef fel ‘nid sbam’ a chliciwch ar y cyswllt.</li>
    <li>Gwiriwch eich bod wedi cofrestru gyda'r cyfeiriad e-bost cywir.</li>
    <li>
        Os nad yw'r camau uchod yn helpu, gallwch 
        <a href="${path.host}/contact-us" class="govuk-link">gysylltu â ni</a>.
    </li>
</ol>`;
    }
}

export function registrationNeedToVerify(email, isEmployer = false) {
    return `
<div class="alert alert-warning" role="alert" data-cy="alert">
    <h2 class="alert-heading">${t("PAGE:CREATE_ACCOUNT:PENDING_VERIFICATION:HEADING")}</h2>
    <p class="govuk-body">${t("PAGE:CREATE_ACCOUNT:PENDING_VERIFICATION:TEXT:1", email)}</p>
    <p class="govuk-body">${t("PAGE:CREATE_ACCOUNT:PENDING_VERIFICATION:TEXT:2")}</p>
    <p class="govuk-body">${t(
        "PAGE:CREATE_ACCOUNT:PENDING_VERIFICATION:TEXT:3",
        `<a class="govuk-link govuk-link--inverse" href="${path.host}${isEmployer ? "/employer" : ""}/verify-email">`,
        "</a>"
    )}</p>
</div>`;
}

export function registrationUserExists(isEmployer = false) {
    return `
<div class="error-summary" tabindex="-1" role="alert" data-cy="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:CREATE_ACCOUNT:USER_EXISTS:HEADING")}</h2>
    <p class="govuk-body">${t(
        "PAGE:CREATE_ACCOUNT:USER_EXISTS:TEXT",
        `<a class="govuk-link" href="${path.host}${isEmployer ? "/employer" : ""}/forgot-password">`,
        "</a>"
    )}</p>
</div>`;
}
