import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export const uploadErrors = {
    limit_reached: t("PAGE:APPLY:CV:ERROR:LIMIT_REACHED"),
    missing_cv: t("PAGE:APPLY:CV:ERROR:MISSING_CV"),
    unsupported_content_type: t("PAGE:APPLY:CV:ERROR:UNSUPPORTED_CONTENT_TYPE"),
    unsupported_format: t("PAGE:APPLY:CV:ERROR:UNSUPPORTED_FORMAT"),
    empty_cv: t("PAGE:APPLY:CV:ERROR:EMPTY_CV"),
    file_too_large: t("PAGE:APPLY:CV:ERROR:FILE_TOO_LARGE"),
    filename_too_long: t("PAGE:APPLY:CV:ERROR:FILENAME_TOO_LONG"),
    timeout: t("PAGE:APPLY:CV:ERROR:TIMEOUT"),
    virus_found: t("PAGE:APPLY:CV:ERROR:VIRUS_FOUND"),
};

export function uploadSuccess() {
    return `
<div class="success-summary" role="alert">
    <h2 class="govuk-heading-m success-summary-heading">${t("PAGE:APPLY:CV:UPLOAD:SUCCESS")}</h2>
    <p class="govuk-body">${t("PAGE:APPLY:CV:UPLOAD:SUCCESS:TEXT")}</p>
</div>
`;
}

export function uploadInProgress() {
    return `
<div class="in-progress-summary" role="alert">
    <h2 class="govuk-heading-m in-progress-summary-heading">${t("PAGE:APPLY:CV:UPLOAD:IN_PROGRESS")}</h2>
    <p class="govuk-body"><span class="pr govuk-!-margin-right-2"></span>${t(
        "PAGE:APPLY:CV:UPLOAD:IN_PROGRESS:TEXT",
    )}</p>
</div>`;
}

export function applicationSuccess() {
    return `
<div class="success-summary" role="alert">
    <h2 class="govuk-heading-m success-summary-heading">${t("PAGE:APPLY:SUCCESS")}</h2>
    <p class="govuk-body">${t("PAGE:APPLY:SUCCESS:TEXT:1")}</p>
    <p class="govuk-body">${t(
        "PAGE:APPLY:SUCCESS:TEXT:2",
        `<a class="govuk-link" href="${path.host}/your-account/activity/applications">`,
        "</a>",
    )}</p>
</div>
`;
}

export function applicationErrors(type, meta) {
    let heading = t("PAGE:APPLY:ERROR:5"),
        text = t("PAGE:APPLY:ERROR:TEXT:5");
    switch (type) {
        case "invalid_email":
            heading = t("PAGE:APPLY:ERROR:1");
            text = t("PAGE:APPLY:ERROR:TEXT:1");
            break;
        case "message_too_long":
            heading = t("PAGE:APPLY:ERROR:2");
            text = t("PAGE:APPLY:ERROR:TEXT:2", meta.length);
            break;
        case "invalid_message":
            heading = t("PAGE:APPLY:ERROR:3");
            text = t("PAGE:APPLY:ERROR:TEXT:3");
            break;
        case "invalid_full_name":
            heading = t("PAGE:APPLY:ERROR:4");
            text = t("PAGE:APPLY:ERROR:TEXT:4");
            break;
    }
    return `
<div class="error-summary" role="alert" tabindex="-1">
  <h2 class="govuk-heading-m error-summary-heading">${heading}</h2>
  <p class="govuk-body">${text}</p>
</div>`;
}
