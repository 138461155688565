import { checkLoginStatus } from "../utilities/authentication.js";
import { emailAlertCreation, emailAlertSuccess, emailAlertError } from "../templates/search.js";

import debounce from "lodash.debounce";
import * as Cookies from "js-cookie";
import { escapeHtml } from "../../common/utilities/util.js";
import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

const auth = checkLoginStatus();

function init() {
    // "Show more" links on the filters pane
    $("a.smr").on("click", function(e) {
        e.preventDefault();
        $(this)
            .css("display", "block")
            .slideUp(300, function() {
                $(this)
                    .prev("ul.smr")
                    .slideDown(300);
            });
    });
    // Changing the "Per page" or the "Sort by" dropdowns
    $(".search-pos-sort select").on("change", function(e) {
        window.location = path.host + "/search" + decodeURIComponent($(this).val());
    });

    // Email alert box: Decide whether to show the signed in or signed out version
    if (!auth) {
        $(".email-alert-signed-out").show();
    } else {
        $(".email-alert-signed-out").replaceWith(
            emailAlertCreation(escapeHtml(decodeURIComponent(uj_search_data.full_title)))
        );
        // Let's also setup the necessary events
        initEmailAlertBox();
    }

    // Toggling the filters column in mobile breakpoint
    $(".filters-toggle").on("click", function(e) {
        e.preventDefault();
        let $cf = $(".column-filters");
        if ($cf.hasClass("visible")) {
            $cf.removeClass("visible");
            $(this).text(t("PAGE:SEARCH:FILTERS:SHOW"));
        } else {
            $cf.addClass("visible");
            $(this).text(t("PAGE:SEARCH:FILTERS:HIDE"));
        }
    });

    // Init the favourites functionality
    initFavourites();
    // Hold the current query in memory so that we can use it on the details page
    storeLastQuery();
}

function storeLastQuery() {
    let queryString = location.search;
    Cookies.set("uj_last_query", queryString);
}

function initEmailAlertBox() {
    $(".email-alert-signed-in .govuk-button").on("click", e => {
        e.preventDefault();
        // Show a spinner
        $(e.target).replaceWith('<span class="pr"/>');
        // Create the notification
        $.ajax({
            url: path.host + "/create_notification",
            type: "POST",
            data: {
                title_c: decodeURIComponent(uj_search_data.full_title),
                squery_c: uj_search_data.serialised
            },
            dataType: "json",
            headers: {
                "X-CSRF-Token": $('meta[name="csrf_token"]').attr("content")
            }
        })
            .done(() => {
                // All is well, show a success message
                $(".email-alert-box").replaceWith(
                    emailAlertSuccess(escapeHtml(decodeURIComponent(uj_search_data.full_title)), auth.email)
                );
            })
            .fail(() => {
                $(".email-alert-box").replaceWith(emailAlertError());
            });
    });
}

/**
 * Set's up the appropriate events depending on whether the user is logged in or not
 * Logged in users:
 * Clicking on a heart icon will save / unsave that ad
 * Logged out users:
 * Clicking on a heart icon will redirect to the /sign-in
 */
function initFavourites() {
    if (!auth) {
        // Any clicks on the heart icons needs to redirect to the sign in page
        // with enough info to redirect back to where the user started the fav action
        $("[data-js-favourite]").on("click", function(e) {
            e.preventDefault();
            // Save the intention on a session cookie
            Cookies.set("uj_intention", "fav_" + $(this).data("js-favourite"));
            location.href = path.host + "/sign-in?after_login=search" + uj_search_data.url;
        });
    } else {
        // Ping /get-saved-ads to get a list of favourited ads
        checkFavourites();
        // Set click handlers on the favourite heart icons
        $("[data-js-favourite]").on("click", function(e) {
            e.preventDefault();
            let $this = $(this);
            $this.toggleClass("active");
            if ($this.hasClass("active")) {
                $this.find(".govuk-visually-hidden").text(lang["PAGE:DETAILS:RHS:LINK:UNSAVE"]);
            } else {
                $this.find(".govuk-visually-hidden").text(lang["PAGE:DETAILS:RHS:LINK:SAVE"]);
            }
            debouncedUpdateFavourite($this.data("js-favourite"));
        });
        // Does the uj_intention cookie exist? If yes and it contains `fav_{job_id}` it means that
        // the user initially wanted to favourite a job, had to login and was redirected back
        let intention = Cookies.get("uj_intention");
        if (intention) {
            let fav = intention.match(/^fav_(\d+)$/);
            if (fav.length === 2) {
                console.log(fav);
                let $job = $("[data-js-favourite=" + fav[1] + "]");
                if ($job.length) {
                    // The job we wanted to favourite is present on this page
                    // Scroll it into view ...
                    $job.get(0).scrollIntoView();
                    // Show it as favourited ...
                    $job.addClass("active");
                    // And call /save-ad to ensure it is favourited ...
                    updateFavourite(fav[1]);
                }
            }
        }
        // Always get rid of the uj_intention cookie
        Cookies.remove("uj_intention");
    }
}

/**
 * The debounced version of updateFavourite (see above)
 * Uses lodash.debounce to ensure this is not fired continuously by click-happy users
 */
const debouncedUpdateFavourite = debounce(updateFavourite, 500);

/**
 * Decides whether to call /save-ad or /unsave-ad depending on the current class
 * of the element with the data-js-favourite attribute == the supplied id
 * @param id - the id of the job ad
 */
function updateFavourite(id) {
    let $job = $('[data-js-favourite="' + id + '"]');
    if ($job.length) {
        if (!$job.hasClass("active")) {
            fetch(`${path.host}/unsave-ad?id=${id}`, {
                method: "DELETE",
                headers: {
                    "X-CSRF-Token": $('meta[name="csrf_token"]').attr("content")
                }
            });
        } else {
            fetch(`${path.host}/save-ad?id=${id}`, {
                method: "GET",
                headers: {
                    "X-CSRF-Token": $('meta[name="csrf_token"]').attr("content")
                }
            });
        }
    }
}

/**
 * Pings /get-saved-ads passing in the ad id's of all the currently displayed ads
 * If it gets anything returned it ensures it shows as being favourited.
 * @returns {boolean}
 */
function checkFavourites() {
    if (!auth) {
        return false;
    }
    let jobsToCheck = [];
    $("[data-js-favourite]").each((i, el) => jobsToCheck.push($(el).data("js-favourite")));
    // Ping /get-saved-ads and pass the list of job ids (joined with a ':')
    $.ajax({
        url: path.host + "/get-saved-ads",
        cache: false,
        data: {
            ids: jobsToCheck.join(":")
        }
    }).done(response => {
        if (response["saved_ads"]) {
            // Iterate through all the saved ads and set them as active
            Object.keys(response["saved_ads"]).forEach(ad => {
                $("[data-js-favourite=" + ad + "]")
                    .addClass("active")
                    .find(".govuk-visually-hidden")
                    .text(lang["PAGE:DETAILS:RHS:LINK:UNSAVE"]);
            });
        }
    });
}

export { init };
