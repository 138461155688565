/**
 * Extracts all parameters from the query string of the current url (even if they have blank values)
 * @example
 * // Current location is /search?foo=&bar=2
 * getAllParams(); // => { foo: "", bar: "2" }
 * @returns {object} All extracted query string parameters and their values
 */
const getAllParams = function() {
    let match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function(s) {
            return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);
    let toReturn = {};
    while ((match = search.exec(query))) {
        toReturn[decode(match[1])] = decode(match[2]);
    }
    return toReturn;
};

/**
 * Looks for a specific query string parameter in the supplied url
 * (or window.location.href if url not specified)
 * @param {string} name The name of the parameter to extract
 * @param {string} [url] The URL to extract the parameter from, defaults to window.location.href if not supplied
 * @returns {null|string} The contents of the parameter
 */
const getParamByName = function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Creates a string to be appended to the query
 * @param {object} obj An object containing the parameters to be serialised
 * @returns {null|string} The parameters as a string
 */
const getSerialisedQuery = function(obj) {
    let str = [];
    Object.keys(obj).map(function(key) {
        if (obj.hasOwnProperty(key)) {
            str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
        }
    });
    if (str.length > 0) {
        return str.join("&");
    }
    return null;
};

export { getAllParams, getParamByName, getSerialisedQuery };
