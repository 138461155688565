import DOMPurify from "isomorphic-dompurify";

/**
 * Returns the appropriate locale string
 * @param {string} langKey - The key to look for in the global lang variable
 * @param {...*} replacements - One or more replacements for the various '%s' placeholders inside lang[langKey]
 * @returns {string} - The locale string with all replacements in place (if any)
 */
export const getLocaleString = (langKey, ...replacements) => {
    if (!window["lang"]) {
        // Lang global needs to be present
        return "lang var missing";
    }
    let toReturn = window.lang[langKey];
    if (!toReturn) {
        return `Missing ${langKey}`;
    }
    // If we have one or more arguments after langKey we'll need to run .replace() for each
    if (toReturn && replacements.length) {
        toReturn = replacements.reduce(function (finalString, replacement) {
            return finalString.replace(/%s/, replacement);
        }, toReturn);
    }
    return toReturn;
};

/**
 * Instead of repeatedly passing the same configuration to DOMPurify.sanitize, you can use the DOMPurify.setConfig method.
 * Your configuration will persist until your next call to DOMPurify.setConfig, or until you invoke DOMPurify.clearConfig to reset it.
 * Remember that there is only one active configuration, which means once it is set, all extra configuration parameters passed to DOMPurify.sanitize are ignored.
 **/
DOMPurify.setConfig({
    ALLOWED_TAGS: ["p", "b", "a", "strong", "span", "br", "h2", "h3", "em"],
    ALLOWED_ATTR: ["target", "href", "class"],
});

/**
 * Wraps the output of getLocaleString() with an object with an __html key
 * to be used in React's dangerouslySetInnerHTML method
 * @param {string} langKey
 * @param {array} replacements
 * @returns {{__html}}
 */
export const getLocaleStringHTML = (langKey, ...replacements) => {
    return {
        __html: DOMPurify.sanitize(getLocaleString(langKey, ...replacements)),
    };
};
