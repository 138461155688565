import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function jobseekerLoggedOut() {
    return `
<li><a class="govuk-link" href="${path.host}/sign-in">${t("NAVBAR:SIGN_IN")}</a></li>
<li><a class="govuk-link" data-intention="view" href="${path.host}/create-account">${t(
        "NAVBAR:CREATE_ACCOUNT"
    )}</a></li>
<li><a class="govuk-link" href="${path.host}/employer">${t("NAVBAR:EMPLOYERS")}</a></li>
`;
}

export function employerLoggedOut() {
    return `
<li><a class="govuk-link" href="${path.host}/employer/sign-in">${t("NAVBAR:SIGN_IN")}</a></li>
<li><a class="govuk-link" href="${path.host}/employer/create-account">${t("NAVBAR:CREATE_ACCOUNT")}</a></li>
<li><a class="govuk-link" href="${path.host}">${t("NAVBAR:JOBSEEKERS")}</a></li>
`;
}
