import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function resetRequestFailed() {
    return `
<div class="error-summary" tabindex="-1" role="alert">
  <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:RESET_PASSWORD:FAILED:HEADING")}</h2>
  <p class="govuk-body">${t("PAGE:RESET_PASSWORD:FAILED:TEXT")}</p>
</div>`;
}

export function doResetFailure() {
    return `
<div class="error-summary" tabindex="-1" role="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:RESET_PASSWORD:FAILURE:HEADING")}</h2>
    <p class="govuk-body">${t("PAGE:RESET_PASSWORD:FAILURE:TEXT")}</p>
    <ul class="list">
        <li><a class="govuk-link" href="${path.host}/reset-password">${t(
        "PAGE:RESET_PASSWORD:FAILURE:LINK:RESEND"
    )}</a></li>
        <li><a class="govuk-link" href="${path.host}/contact-us">${t(
        "PAGE:RESET_PASSWORD:FAILURE:LINK:CONTACT"
    )}</a></li>
    </ul>
</div>`;
}
