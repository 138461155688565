import { checkLoginStatus } from "../utilities/authentication.js";
import { getSearchLink } from "../../common/utilities/util.js";
import { initValidationOn, validate, validateOptional, validateRadio } from "../utilities/validation.js";
import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";
import { reportSuccess } from "../templates/report.js";
import errorSummary from "../templates/errorSummary.js";

const auth = checkLoginStatus();

function init() {
    // Report an ad functionality
    initReportAd();
    // This adds the last search to the breadcrumbs search link
    $(".js-search-results").attr("href", getSearchLink());
}

function initReportAd() {
    // Submitting the repot an ad form
    let $report = $("form[name=report]");
    if ($report.length) {
        initValidationOn($report.find("[name=reason]"));
        initValidationOn($report.find("[name=text]"));
        initValidationOn($report.find("[name=email]"));
        $report.on("submit", function(e) {
            e.preventDefault();
            let proceed = true,
                $reason = $(this).find("[name=reason]"),
                $text = $(this).find("[name=text]"),
                $email = $(this).find("[name=email]"),
                errors = [];

            // Before we do anything else, let's clear any box error boxes
            $(".govuk-error-summary").remove();

            if (!validateRadio($reason)) {
                proceed = false;
                $reason.trigger("raiseError", [t("PAGE:DETAILS:REPORT:REASON:ERROR")]);
                errors.push({ id: "reason-1", error: t("PAGE:DETAILS:REPORT:REASON:ERROR") });
            }
            if (!validate($text, "MAX_500")) {
                proceed = false;
                $text.trigger("raiseError", [t("PAGE:DETAILS:REPORT:NOTES:ERROR")]);
                errors.push({ id: "reportText", error: t("PAGE:DETAILS:REPORT:NOTES:ERROR") });
            }
            if (!validateOptional($email, "EMAIL")) {
                proceed = false;
                $email.trigger("raiseError", [t("PAGE:DETAILS:REPORT:EMAIL:ERROR")]);
                errors.push({ id: "email", error: t("PAGE:DETAILS:REPORT:EMAIL:ERROR") });
            }
            if (proceed) {
                let $melikoupa = $(".melikoupa input");
                $.ajax({
                    url: path.host + "/report_ad",
                    type: "POST",
                    cache: false,
                    data: {
                        id: $("#reportId").val(),
                        reason: $("[name=reason]")
                            .filter(":checked")
                            .val(),
                        text_c: $("#reportText").val(),
                        email: $("[name=email]").val(),
                        [$melikoupa.attr("name")]: $melikoupa.val()
                    }
                })
                    .done(() => {
                        $("[name=report]")
                            .hide()
                            .before(reportSuccess());
                        $(".back-to-search")
                            .show()
                            .find("a")
                            .attr("href", getSearchLink());
                    })
                    .fail((xhr, status, err) => {
                        // TODO: Modal error here!
                        console.error("Oops!", xhr);
                    });
            } else {
                // The user has validation errors and we need to update the view with an error sumary and new document title
                if (!document.title.includes(t("FAJ:ERROR"))) {
                    document.title = `${t("FAJ:ERROR")} ${document.title}`;
                }
                $("#errorSummary").before(errorSummary(errors));
                $(".govuk-error-summary").focus();
            }
        });
    }
}

export { init };
