function init() {
    $(".js-submit").on("click", function(e) {
        e.preventDefault();
        submit();
    });
}

// Submit functionality
function submit() {
    let url = path.host + "/search?adv=1";
    // Text fields
    $("#what_wrd_c,#what_ph_c,#what_or_c,#what_excl_c,#what_title_c").each(function() {
        let name = $(this).attr("name");
        let val = $.trim($(this).val());
        if (val) {
            url += "&" + name + "=" + encodeURIComponent(val);
        }
    });
    // Salary min
    let $salaryMin = $("#adv_sf");
    if ($salaryMin.val()) {
        url += "&sf=" + $salaryMin.val();
    }
    // Salary max
    let $salaryMax = $("#adv_st");
    if ($salaryMax.val()) {
        url += "&st=" + $salaryMax.val();
    }
    // Category
    let $category = $("#adv_cat");
    if ($category.val()) {
        url += "&cat=" + $category.val();
    }
    // Radio groups
    $(".govuk-radios__item :radio:checked").each(function() {
        let name = $(this).attr("name");
        let val = $(this).val();
        if (val) {
            url += "&" + name + "=" + val;
        }
    });
    // Checkbox groups
    // $(".govuk-radios__item :checkbox:checked").each(function() {
    //     let name = $(this).attr("name");
    //     let val = $(this).val();
    //     if (val) {
    //         url += "&" + name + "=" + val;
    //     }
    // });
    // Location
    let $loc = $("#adv_location");
    if ($.trim($loc.val())) {
        url += "&w=" + $.trim($loc.val());
        // Distance
        let $dist = $("#adv_dist");
        if ($dist.val()) {
            url += "&d=" + $dist.val();
        }
    }
    // Paging
    let $perPage = $("#adv_per_page");
    if ($perPage.val() != "10") {
        url += "&" + $perPage.attr("name") + "=" + $perPage.val();
    }
    // Sorting
    let $sorting = $("#adv_sorting");
    if ($sorting.val()) {
        url += "&" + decodeURIComponent($sorting.val());
    }
    // Disability Confident
    let $disability = $("#adv_disability_confident:checkbox:checked");
    if ($disability.val()) {
        url += "&" + $disability.attr("name") + "=" + $disability.val();
    }
    // Only submit if url has changed by one of the if statements above
    if (url != path.host + "/search?adv=1") {
        // Do the redirect
        window.location = url;
    }
}

export { init };
