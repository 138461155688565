import * as types from "./actionTypes.js";
// Api endpoints
import api from "../api/authApi.js";
// Response Handling
import { apiCallFailed, addMessage } from "./messageActions.js";
// Utilities
import { getLocaleString as t } from "../common/utilities/getLocaleString.js";
import * as Cookies from "js-cookie";

/**
 * Checks the user's cookies to see if they are authenticated
 * @return {object} user = {uid, token}
 */
export function checkAuth() {
    let auth = Cookies.get("page_load");
    if (auth) {
        auth = auth.match(/^.+\.(.+)\..+$/);
        const user = JSON.parse(window.atob(auth[1]));
        if (user.email) {
            return user;
        }
    }
    return false;
}

export function getLogin(data) {
    return function(dispatch) {
        dispatch({ type: types.SET_APP_LOADING, data: true });
        return api.getLogin().then(response => {
            if (response.status < 200 || response.status >= 300) {
                response.type = "toast";
                dispatch(
                    apiCallFailed({
                        type: "notification",
                        className: "error-summary",
                        setTimeout: 8000,
                        ...response
                    })
                );
            } else {
                dispatch({ type: types.GET_LOGIN_SUCCESS, ...response });
                dispatch({ type: types.GET_USER_SUCCESS, ...response });
                dispatch({ type: types.SET_APP_LOADING, data: false });
            }
        });
    };
}

/**
 * Depending where we are return the app token
 * @return {string} token
 */
export function getAppAuthToken() {
    let appAuth = Cookies.get(`${path.portal}Auth`);
    if (appAuth) {
        return appAuth;
    }
    return false;
}

/**
 * Depending where we are set a new app token
 * @param {string} response - This represents the token returned after a successful request
 */
export function setAppAuthCookie(response) {
    if (response) {
        Cookies.remove(`${path.portal}Auth`);
        Cookies.set(`${path.portal}Auth`, response, { path: "/" });
    }
}

export function setAppAuth(response) {
    setAppAuthCookie(response);
    const user = checkAuth();
    return function(dispatch) {
        dispatch({ type: types.SET_APP_AUTH_SUCCESS, email: user.email });
    };
}

//FIXME This seems a bit dirty and should be refactored
export function resetPassword(email) {
    return dispatch => {
        // First tell the store we'll start the request process
        dispatch({
            type: types.REQUEST_PASSWORD_RESET_IN_PROGRESS
        });
        // Request to send a reset password email
        return (
            fetch(`${path.host}/password-reset?email=${encodeURIComponent(email)}&employer=1`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                    Accept: "application/vnd.api+json"
                },
                credentials: "same-origin"
            })
                // TODO: Handle errors here
                .then(response => response.json())
                .then(json =>
                    dispatch({
                        type: types.REQUEST_PASSWORD_RESET_SUCCESS,
                        data: json
                    })
                )
        );
    };
}