import { getLocaleString as t } from "../../common/utilities/getLocaleString.js";

export function changeFrequencySuccess() {
    return `
<div class="alert alert-success govuk-!-margin-top-3" role="alert">
    <h2 class="alert-heading">${t("PAGE:UNSUBSCRIBE:CHANGE_FREQ:SUCCESS")}</h2>
    <p class="govuk-body">${t("PAGE:UNSUBSCRIBE:CHANGE_FREQ:SUCCESS:TEXT:1")}</p>
    <div><a class="govuk-link" href="${path.host}/your-account/email-alerts">${t(
        "PAGE:UNSUBSCRIBE:CHANGE_FREQ:SUCCESS:TEXT:2"
    )} &raquo;</a></div>
</div>`;
}

export function genericError() {
    return `
<div class="error-summary" tabindex="-1" role="alert">
    <h2 class="govuk-heading-m error-summary-heading">${t("PAGE:UNSUBSCRIBE:ERROR:GENERIC")}</h2>
    <p class="govuk-body">${t(
        "PAGE:UNSUBSCRIBE:ERROR:GENERIC:TEXT",
        `<a class="govuk-link" href="${path.host}/contact-us">`,
        "</a>"
    )}</p>
</div>`;
}

export function unsubscribeSuccess() {
    return `
<div class="alert alert-success govuk-!-margin-top-3" role="alert">
    <h2 class="alert-heading">${t("PAGE:UNSUBSCRIBE:SUCCESS")}</h2>
    <div><a class="govuk-link" href="${path.host}/your-account/email-alerts">${t(
        "PAGE:UNSUBSCRIBE:SUCCESS:TEXT"
    )} &raquo;</a></div>
</div>`;
}
