// Extend baseApi
import baseApi from "./baseApi.js";
// Utilities
import { getUrl } from "../common/utilities/util.js";
// Internet Explorer <= 11 support
if (!window.Promise) {
    window.Promise = Promise;
}

let headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
};
const credentials = "same-origin";

export default class api extends baseApi {
    /**
     * Checks to see if a user is authenticated and returns a data object if they are
     * @return {object} title - contains uid, token, exp.
     */
    static getLogin() {
        return fetch(getUrl(`${path.api}login`), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            credentials
        })
            .then(response => this.checkStatus(response))
            .then(response => this.parseJSON(response));
    }
    /**
     * Retrieves the current authenticated user's profile
     * @return {object} data - id, attributes, included, type.
     */
    static getUserProfile(data) {
        return fetch(getUrl(`${path.api}users/${data}`), {
            method: "GET",
            headers: this.getAppHeader(),
            credentials
        })
            .then(response => this.checkStatus(response))
            .then(response => this.parseJSON(response));
    }
}
