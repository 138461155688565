import { getParamByName } from "../../common/utilities/queryString.js";

export function initGeosuggest() {
    $("[data-js=geosuggest]").each(function() {
        // On "where" field change, remove the location id attribute in order to
        // allow the user input to be used for the search
        $(this).one("input", function() {
            $(this).removeAttr("data-loc_id");
            $(this).removeData("loc_id");
        });

        // FJ-1596: Do we have the loc url param? Retain
        let location_id = getParamByName("loc");
        if (location_id) {
            $(this).data({ loc_id: location_id });
        }
        $(this).autocomplete({
            minChars: 2,
            autoSelectFirst: true,
            serviceUrl: path.dyn_cached + "/geosuggest",
            paramName: "where_c",
            dataType: "json",
            deferRequestBy: 300,
            transformResult: function(response, originalQuery) {
                let toReturn = {
                    suggestions: response.locations.map(loc => {
                        return {
                            value: loc.value,
                            data: loc
                        };
                    })
                };
                // FJ-1842: Do a locale-aware check of originalQuery
                // to see if it matches any remote or hybrid keywords
                let locale = window?.path?.lang_code;
                let match = getRemoteKeywordMatch(originalQuery, locale);
                // If match is not false, it'll either be "remote" or "hybrid"
                // Prepend the locale-specific "remote" or "hybrid" terms on the autocomplete suggestions list
                if (locale === "en") {
                    if (match === "remote") {
                        toReturn.suggestions.unshift(
                            { value: "Fully remote", data: "fully_remote" },
                            { value: "Remote", data: "fully_remote" },
                            { value: "Remote working", data: "fully_remote" }
                        );
                    } else if (match === "hybrid") {
                        toReturn.suggestions.unshift(
                            { value: "Hybrid", data: "hybrid" },
                            { value: "Hybrid remote", data: "hybrid" },
                            { value: "Hybrid working", data: "hybrid" }
                        );
                    }
                } else if (locale === "cy") {
                    if (match === "remote") {
                        toReturn.suggestions.unshift(
                            { value: "Yn gyfan gwbl o bell", data: "fully_remote" },
                            { value: "O bell", data: "fully_remote" },
                            { value: "Gweithio o bell", data: "fully_remote" }
                        );
                    } else if (match === "hybrid") {
                        toReturn.suggestions.unshift(
                            { value: "Hybrid", data: "hybrid" },
                            { value: "Hybrid o bell", data: "hybrid" },
                            { value: "Gweithio hybrid", data: "hybrid" }
                        );
                    }
                }
                return toReturn;
            },
            onSelect: function(suggestion) {
                if (suggestion.data === "fully_remote" || suggestion.data === "hybrid") {
                    $(this).data({
                        remote: suggestion.data
                    });
                } else {
                    // User has picked a location. Store the tokenized details as data attributes
                    $(this).data({
                        loc_id: suggestion.data.id,
                        loc_lbl: suggestion.data.label,
                        loc_tag: suggestion.data.tag
                    });
                }
            },
            onInvalidateSelection: function() {
                // User has changed manually the input field text. let's clear any tokenized data if available
                $(this).data({
                    loc_id: null,
                    loc_lbl: null,
                    loc_tag: null,
                    remote: null
                });
            }
        });
    });
}

/**
 * Matches supplied string against a locale-specific list of three keywords / sentences that reference remote work
 * @param query - A string
 * @param locale - Defaults to "en", can be "cy"
 * @returns {boolean|string} - false if no match found, otherwise "remote" or "hybrid" depending on what was matched
 */
export function getRemoteKeywordMatch(query, locale = "en") {
    // Trim and lowercase the supplied query
    query = query.toLowerCase();
    // Act depending on locale
    if (locale === "en") {
        if ("fully remote".indexOf(query) >= 0) {
            return "remote";
        } else if ("hybrid remote".indexOf(query) >= 0) {
            return "hybrid";
        } else if ("remote working".indexOf(query) >= 0) {
            return "remote";
        } else if ("hybrid working".indexOf(query) >= 0) {
            return "hybrid";
        } else if ("remote".indexOf(query) >= 0) {
            return "remote";
        } else if ("hybrid".indexOf(query) >= 0) {
            return "hybrid";
        }
    } else if (locale === "cy") {
        if ("yn gyfan gwbl o bell".indexOf(query) >= 0) {
            return "remote";
        } else if ("hybrid o bell".indexOf(query) >= 0) {
            return "hybrid";
        } else if ("gweithio o bell".indexOf(query) >= 0) {
            return "remote";
        } else if ("gweithio hybrid".indexOf(query) >= 0) {
            return "hybrid";
        } else if ("o bell".indexOf(query) >= 0) {
            return "remote";
        } else if ("hybrid".indexOf(query) >= 0) {
            return "hybrid";
        }
    }
    // Return false if we've not matched against anything
    return false;
}
